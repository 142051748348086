import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& hr': {
      backgroundColor: 'rgba(0, 0, 0, 0.52)',
      margin: '10px 0'
    },
    minHeight: '90vh',
    height: '100%',
  },
  paperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 60,
  },
  table: {
    marginBottom: 60,
  },
  tableHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      fontSize: 20,
      margin: 0,
    },
  },
  arrowDown: {
    transform: 'rotate(0deg) !important',
  },
  avatar: {
    position: 'relative',
  },
  arrowUp: {
    transform: 'rotate(180deg)',
    transition: 'all .3s ease',
    cursor: 'pointer',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      margin: 'auto 10px',
      cursor: 'pointer'
    }
  },
  tableWrapper: {
    overflow: 'hidden',
    transition: 'height .3s ease-in',
    height: 0,
  },
  tableWrapperIsOpen: {
    transition: 'height .3s ease-in',
    height: 'auto', 
  },
  paperHeaderWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 'auto',
    position: 'relative',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 10,
    }
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 10,
    }
  },
  done: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: 'white'
    }
  },
  logo: {
    width: 220,
    height: 220,
    borderRadius: 110,
    backgroundColor: '#bebebe',
    alignSelf: 'center',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  modalWrapper: {
    position: 'absolute',
    width: '70%',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'auto',
    backgroundColor: '#fff',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& > div': {
      '&:nth-child(2)': {
        marginBottom: 20,
      }
    }
  },
  arrowBack: {
    cursor: 'pointer',
    color: 'gray',
  },
  chatInfo: {
    flex: 1.5,
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  chatField: {
    marginTop: 40,
    '& p': {
      color: 'gray'
    }
  },
  photoWrapper: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 220,
    height: 220,
    borderRadius: 110,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    }
  },
  photo: {
    width: 50,
    height: 50,
    color: 'white',
  },
  chatTitle: {
    '& p': {
      fontSize: 20,
      margin: 0,
    }
  },
}))