import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import fallbackImage from '../../assets/fallback.jpg'
import trash from '../../assets/images/icons/trash.png'
import edit from '../../assets/images/icons/edit.png'

import moment from 'moment';

// import EditNewsModal from '../../components/EditNewsModal';
import { getNews, deleteNews } from '../../actions';
import useStyles from './styles';

export default ({ location, history }) => {
  const classes = useStyles();
  const [newsData, setNewsData] = React.useState({});
  // const [editNewsData, seteditNewsData] = React.useState({ isOpen: false, data: {} });
  const dataId = location.pathname.replace(/\D/g, '');

  const onGetNews = React.useCallback(async () => {
    const dataId = location.pathname.replace(/\D/g, '');
    const { success, result } = await getNews(dataId);
    if (success) {
      setNewsData(result)
    }
  }, [location.pathname])

  React.useEffect(() => {
    onGetNews();
  }, [onGetNews]);

  const onEdit = () => {
    history.push(`edit/${dataId}`)
    // seteditNewsData({
    //   isOpen: true,
    // });
  }

  // const onClose = () => {
  //   seteditNewsData({
  //     isOpen: false,
  //   });
  //   onGetNews();
  // }

  const onDelete = async () => {
    await deleteNews(newsData.id)
    history.push('/dashboard/news')
  }

  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <ArrowBack className={classes.arrowBack} onClick={() => { history.goBack() }} />
        <div>
          <div className={classes.image} style={{ backgroundImage: `url(${newsData.image || fallbackImage})` }} />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Новость</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div>
              <span>Категория</span>
              <Typography>{newsData.tag_name}</Typography>
            </div>
            <div className={classes.dates}>
              <div>
                <span>Активна с</span>
                <Typography>{moment(newsData.active_date_from).format('DD MMMM YYYY hh:mm')}</Typography>
              </div>
              <div>
                <span>Активна по</span>
                <Typography>{moment(newsData.active_date_to).format('DD MMMM YYYY hh:mm')}</Typography>
              </div>
            </div>
            <div>
              <span>Заголовок</span>
              <Typography>{newsData.title}</Typography>
            </div>
            <div>
              <span>Текст</span>
              <Typography>{newsData.description}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <ButtonBase className={classes.buttonRound} onClick={onEdit}>
            <img style={{width: 20}} src={edit} alt="edit button"/>
          </ButtonBase>
          <ButtonBase className={classes.buttonRound} onClick={onDelete}>
            <img style={{width: 20}} src={trash} alt="delete button"/>
          </ButtonBase>
        </div>
      </div>
      {/*<EditNewsModal*/}
      {/*  isEdit={true}*/}
      {/*  isOpen={editNewsData.isOpen}*/}
      {/*  data={newsData || {}}*/}
      {/*  onClose={onClose}*/}
      {/*/>*/}
    </div>
  );
};
