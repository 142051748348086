import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    width: '90%',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'auto',
    backgroundColor: '#fff',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  contentItem: {
    margin: '20px 0',
    '& p': {
      color: 'grey'
    }
  },
  contentItemWithMap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      position: 'relative'
    }
  },
  greyText: {
    color: 'grey'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 40,
    maxHeight: '60vh',
    overflow: 'scroll',
  },
  mapWrap: {
    height: '300px !important',
    margin: 'unset !important',
    flex: 1,
  },
  image: {
    width: 300,
    height: 300,
    marginRight: 20,
    borderRadius: 4,
    backgroundSize: 'cover',
    backgroundColor: '#999',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      boxShadow: 'none',
      color: '#fff',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:last-child': {
        marginLeft: 27,
      },
    }
  },
  closeIcon: {
    cursor: 'pointer'
  },
  rangeInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      flex: 1,
      width: '100%',
    },
    '&>div:first-child': {
      marginRight: 20
    },
    '&>div:last-child': {
      marginLeft: 20
    }
  },
  logoWrapper: {
    position: 'relative',
  },
  photoWrapper: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    height: '300px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    }
  },
  photo: {
    width: 50,
    height: 50,
    color: 'white',
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 3,
    cursor: 'pointer',
    top: 0,
  },
  formControl: {
    userSelect: 'none',
    marginBottom: 20,
  },
}));
