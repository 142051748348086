import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import ButtonBase from '@material-ui/core/ButtonBase';
import Photo from '@material-ui/icons/PhotoCameraOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';

import Table from '../../components/Table';
import useStyles from './styles';
import { getUsersList, createChat, getCities } from '../../actions';
import TextField from "@material-ui/core/TextField";


function parseData(data) {
  return ({
    headers: [
      { dataAccessor: 'picture', title: '', sortable: false },
      { dataAccessor: 'fullName', title: 'ФИО', sortable: false },
      { dataAccessor: 'phoneNumber', title: 'Телефон', sortable: false },
      { dataAccessor: 'city', title: 'Город', sortable: false },
      { dataAccessor: 'status', title: 'Статус', sortable: false },
      { dataAccessor: 'lastUpdate', title: 'Последнее обновление', sortable: false },
    ],
    body: data.map(x => ({
      id: x.id,
      picture: x.picture,
      fullName: `${x.last_name || '-'} ${x.first_name || '-'} ${x.fatherland || '-'}`,
      phoneNumber: x.phone_number || '-',
      city: x.city_name || '-',
      status: x.status,
      lastUpdate: x.last_login ? moment(x.last_login).format('DD MMMM YYYY HH:MM') : '-'
    }))
  });
}

export default ({ history }) => {
  const [tableData, setTableData] = React.useState(parseData([]));
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [imageData, setImage] = React.useState({ iamge: '', file: '' })
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [city, setCity] = React.useState('');
  const [groupName, setGroupName] = React.useState('');
  const [imageError, setImageError] = React.useState('Выберите изображение');
  const [error, setError] = React.useState('');
  const classes = useStyles();

  const [cities, setCities] = React.useState([]);

  const onGetCities = async () => {
    const { result, success } = await getCities();
    if (success) {
      setCities(result || [])
    }
  }

  const onGetUsersList = async (data) => {
    const { success, results, count } = await getUsersList(data);
    if (success) {
      setTableData(parseData(results))
      setPageData({ page: data.page, count });
    }
  }

  React.useEffect(() => {
    onGetUsersList({ page: 1 });
    onGetCities();
  }, []);

  const changeGroupName = ({ target: { value } }) => {
    setGroupName(value)
    setError('');
    setImageError('');
  }

  const changeCity = ({ target: { value } }) => {
    setCity(value)
    setError('');
    setImageError('');
  }
  const onCancel = () => { };
  const onCreate = async () => {
    if (!imageData.file) {
      setImageError('Выберите изображение');
    }
    if (selectedUsers.length && city && imageData.file && groupName.length) {
      const formData = new FormData();
      formData.append('image', imageData.file);
      formData.append('city', city);
      formData.append('group_name', groupName);
      formData.append('active_participants', `[${selectedUsers}]`);
      const { success, result, message } = await createChat(formData);
      if (success) {
        history.push(`${result.id}`)
      } else {
        setError('Груповой чат с таким названием уже существует');
      }
    }
  };
  const onClickRow = values => {
    setSelectedUsers(val =>
      val.includes(values.id) > 0 ?
        val.filter(x => x !== values.id)
        :
        [...val, values.id]
    )
  }
  const onChangePage = (e, newPage) => {
    onGetUsersList({ page: newPage + 1 });
  }

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setImage({ image: URL.createObjectURL(target.files[0]), file: target.files[0] })
    }
    setError('');
    setImageError('');
  }

  return (
    <div className={classes.paper}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderWrapper}>
          <ArrowBack className={classes.arrowBack} onClick={() => { history.goBack() }} />
          <div className={classes.logoWrapper}>
            <div className={classes.logo} style={{ backgroundImage: `url(${imageData.image})` }} />
            <div className={classes.photoWrapper}>
              <Photo className={classes.photo} />
              <input type="file" name="picture" className={classes.input} onChange={onChangeImage} />
            </div>
            <Typography align="center" color="error">{imageError || ''}</Typography>
          </div>
        </div>
        <div className={classes.chatInfo}>
          <div className={classes.chatTitle}>
            <Typography>Создание чата</Typography>
          </div>
          <Divider />
          <div className={classes.chatField}>
            <TextField
              type="text"
              id="chat_group"
              name="chat_group"
              label="Название группы"
              variant="outlined"
              fullWidth={true}
              size="small"
              onChange={changeGroupName}
            />
          </div>
          <div className={classes.chatField}>
            <Typography>Город</Typography>
            <Select value={city} onChange={changeCity}>
              {cities.map(x => (
                <MenuItem value={x.city_name}>{x.city_name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.chatFieldError}>
              <Typography color="error">{error || ''}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.paperContent}>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div className={classes.tableTitle}>
              <Typography>Пользователи</Typography>
            </div>
            <div className={classes.tableActions}>
              <ButtonBase color="secondary" size="large" variant="contained" onClick={onCancel}>Отменить</ButtonBase>
              <ButtonBase disabled={!city || !selectedUsers.length} color="primary" size="large" variant="contained" onClick={onCreate}>Создать</ButtonBase>
            </div>
          </div>
          <div className={classes.tableWrapper}>
            <Table
              data={tableData}
              handleClickRow={onClickRow}
              onChangePage={onChangePage}
              selectedUsers={selectedUsers}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
