export const USER_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    name: 'city',
    inputType: ''
  },
  // {
  //   title: 'Количество пользователей',
  //   mode: 'range',
  //   inputType: 'number',
  //   nameFirst: 'count_after',
  //   nameSecond: 'count_before'
  // },
  // {
  //   title: 'Последнее обновление',
  //   mode: 'range',
  //   inputType: 'date',
  //   nameFirst: 'last_update_after',
  //   nameSecond: 'last_update_before'
  // },
  // {
  //   title: 'Создан',
  //   mode: 'range',
  //   inputType: 'date',
  //   nameFirst: 'created_at_after',
  //   nameSecond: 'created_at_before'
  // },
];

export const CHATS_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    name: 'city',
  },
  {
    title: 'Последнее обновление',
    mode: 'range',
    inputType: 'date',
    nameFirst: 'group_messages_after',
    nameSecond: 'group_messages_before'
  },
  {
    title: 'Создан',
    mode: 'range',
    inputType: 'date',
    nameFirst: 'created_at_after',
    nameSecond: 'created_at_before'
  },
];

export const ADDRESSES_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    name: 'city',
    inputType: ''
  },
];

export const TRADE_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    name: 'city',
    inputType: ''
  },
];

export const NEWS_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    name: 'city',
    inputType: ''
  },
];

export const FAQ_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    inputType: ''
  },
  {
    title: 'Количество пользователей',
    mode: 'range',
    inputType: 'number'
  },
  {
    title: 'Последнее обновление',
    mode: 'range',
    inputType: 'date'
  },
  {
    title: 'Создан',
    mode: 'range',
    inputType: 'date'
  },
];

export const PARTNERS_FILTERS = [
  {
    title: 'Город',
    mode: 'select',
    inputType: ''
  },
  {
    title: 'Количество пользователей',
    mode: 'range',
    inputType: 'number'
  },
  {
    title: 'Последнее обновление',
    mode: 'range',
    inputType: 'date'
  },
  {
    title: 'Создан',
    mode: 'range',
    inputType: 'date'
  },
];