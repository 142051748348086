import React, { useState, useEffect } from "react";
import moment from "moment";

import FiltersModal from "../../components/FiltersModal";
import SearchBox from "../../components/TableSearch";
import AddButton from "../../components/AddButton";
import Table from "../../components/Table";
import AddressDetail from "../../components/AddressDetail";
import EditAddress from "../../components/EditAddress";
import Map from "../../components/Map";
import { debounce, ADDRESSES_FILTERS } from "../../utils";
import { getAddressesList } from "../../actions";
import ArrowBack from "@material-ui/icons/ArrowBackIos";

function parseData(data) {
  return {
    headers: [
      { dataAccessor: "picture", title: "", sortable: false },
      { dataAccessor: "name", title: "Название места", sortable: true },
      { dataAccessor: "average_rating", title: "Оценка", sortable: true },
      { dataAccessor: "total_grades", title: "Всего оценок", sortable: true },
      { dataAccessor: "created_at", title: "Создано", sortable: true },
      {
        dataAccessor: "last_comment",
        title: "Последний комментарий",
        sortable: true,
      },
      {
        dataAccessor: "lastUpdate",
        title: "Последнее обновление",
        sortable: true,
      },
    ],
    body: data.map((x) => ({
      id: x.id,
      picture: x.image,
      name: x.name,
      average_rating: x.average_rating,
      address: x.address,
      point: x.point,
      total_grades: x.total_grades,
      comments: x.comments,
      created_at: moment(x.created_at).format("DD MMMM YYYY HH:MM"),
      last_comment: x.last_comment || "-",
      lastUpdate: moment(x.created_at).format("DD MMMM YYYY HH:MM"),
    })),
  };
}

export default function () {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [filtersData, setFiltersData] = useState({});
  const [pageData, setPageData] = useState({ page: 1, count: 0 });
  const [tableData, setTableData] = useState(parseData([]));
  const [lastMarker, setLastMarker] = useState(null);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [addModalData, setAddModalData] = useState({ isOpen: false });

  const onGetAddressesList = async ({ page, search, ordering, ...filters }) => {
    setFiltersData({ page, search, ...filters });
    const { success, results, count } = await getAddressesList({
      page,
      search,
      filters,
      ordering,
    });
    if (success) {
      const newTableData = {
        ...parseData(results),
        body: parseData(results).body.map((x) => ({
          ...x,
          marker: tableData.body.find((i) => i.id === x.id)?.marker || null,
        })),
      };
      setTableData(newTableData);
      setPageData({ page, count });
    }
  };

  useEffect(() => {
    onGetAddressesList({ page: 1 });
  }, []);

  const onClearFilters = () => {
    onGetAddressesList({ page: 1 });
  };
  const onChangePage = (e, newPage) => {
    onGetAddressesList({ ...filtersData, page: newPage + 1 });
  };
  const onCloseFilters = () => setFiltersIsOpen(false);
  const onClickFilters = () => setFiltersIsOpen(true);
  const debouncedUsersGet = debounce(500, onGetAddressesList);

  const onChangeSearch = ({ target: { value } }) => {
    setModalData({ isOpen: false });
    debouncedUsersGet({ page: 1, ...filtersData, search: value });
  };

  const onClickAdd = (values) => {
    setAddModalData({ isOpen: true, data: values });
  };

  const onSave = (values) => {
    const vals = {
      page: 1,
      ...values,
    };
    debouncedUsersGet(vals);
    setFiltersIsOpen(false);
  };

  const onCloseAddModal = () => {
    setAddModalData({ isOpen: false, data: {} });
    onGetAddressesList({ page: 1, ...filtersData });
  };

  const handleClickRow = ({ marker, ...rest }) => {
    lastMarker && lastMarker.togglePopup();
    setLastMarker(marker);
    if (marker) {
      marker.getPopup().on("close", (x) => {
        setLastMarker(null);
      });
      marker.togglePopup();
    }
    setModalData({ isOpen: true, marker, ...rest });
  };

  const renderMarkersEvents = (markers) => {
    const modifiedTableData = { ...tableData };
    modifiedTableData.body = tableData.body.map((x, i) => ({
      ...x,
      marker: markers[i],
    }));
    setTableData(modifiedTableData);
  };

  function handleSort(name) {
    onGetAddressesList({ page: 1, ordering: name });
  }

  const backToList = () => {
    setModalData({ isOpen: false });
    onGetAddressesList({ page: 1, ...filtersData });
  };

  return (
    <React.Fragment>
      <SearchBox
        resultCount={tableData.body.length}
        onChangeSearch={onChangeSearch}
        onClickFilters={onClickFilters}
        onClearFilters={onClearFilters}
        // onClickAdd={onClickAdd}
      />
      <div style={{ display: "flex" }}>
        {modalData.isOpen && (
          <ArrowBack
            style={{ cursor: "pointer", color: "gray" }}
            onClick={() => setModalData({ isOpen: false })}
          />
        )}
        <Map
          data={tableData.body}
          renderMarkersEvents={renderMarkersEvents}
          pageData={pageData}
          setModalData={setModalData}
        />
      </div>
      {!modalData.isOpen ? (
        <Table
          data={tableData}
          handleClickRow={handleClickRow}
          onChangePage={onChangePage}
          pageData={pageData}
          onSort={handleSort}
        />
      ) : (
        <AddressDetail
          refetch={onGetAddressesList}
          backToList={backToList}
          {...modalData}
        />
      )}
      <FiltersModal
        isOpen={filtersIsOpen}
        onSave={onSave}
        onClose={onCloseFilters}
        fields={ADDRESSES_FILTERS}
        fieldsData={filtersData}
      />
      <EditAddress
        isOpen={addModalData.isOpen}
        data={addModalData.data || {}}
        onClose={onCloseAddModal}
        isEdit={false}
        refetch={onGetAddressesList}
      />
      <AddButton onClick={onClickAdd} />
    </React.Fragment>
  );
}
