import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    width: '65vh',
    height: '90vh',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    transform: 'translateY(5vh)',
  },
  contentItem: {
    margin: '20px 0',
    '& p': {
      color: 'grey'
    }
  },
  photoWrapper: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 300,
    height: 300,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    }
  },
  photo: {
    width: 50,
    height: 50,
    color: 'white',
  },
  greyText: {
    color: 'grey'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 40,
    overflow: 'scroll',
  },
  image: {
    width: 300,
    height: 300,
    borderRadius: 4,
    backgroundSize: 'cover',
    backgroundColor: '#999',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    margin: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      boxShadow: 'none',
      color: '#fff',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:last-child': {
        marginLeft: 27,
      },
    }
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    zIndex: 3,
    top: 0,
  },
  contentItemWithMap: {
    position: 'relative'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  rangeInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      flex: 1,
      width: '100%',
    },
    '&>div:first-child': {
      marginRight: 20
    },
    '&>div:last-child': {
      marginLeft: 20
    }
  },
  formControl: {
    userSelect: 'none',
    marginBottom: 20,
  },
  errorMess: {
    display: "block",
    textAlign: "right",
  }
}));
