import React, {useEffect, useState} from 'react'
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

function PaymentDetailsModal({isOpen, onClose, onSave, data}) {
    const classes = useStyles()
    const [paymentData, setPaymentData] = useState({
        account_number: '',
        card_number: '',
        bik: '',
        full_name: ''
    })

    useEffect(() => {
        setPaymentData({
            account_number: data?.account_number || '',
            card_number: data?.card_number || '',
            bik: data?.bik || '',
            full_name: data?.full_name || ''
        })
    }, [data])

    const [errors, setErrors] = useState({
        account_number: false,
        card_number: false,
        bik: false,
        full_name: false
    })

    const onChange = ({ target: { value, name } }) => {
        setErrors({...errors,  [name]: false});
        setPaymentData({ ...paymentData, [name]: value });
    }

    const handleClose = () => {
        setPaymentData({
            account_number: '',
            card_number: '',
            bik: '',
            full_name: ''
        });

        setErrors({
            account_number: false,
            card_number: false,
            bik: false,
            full_name: false
        })

        onClose();
    }

    const handleSave = async () => {
        if (!Object.keys(paymentData).map(v => {
            if(!paymentData[v]) {
                setErrors(errors => {
                    return {...errors, [v]: true};
                })
            }
            return paymentData[v];
        }).includes(false)) {
           await onSave(paymentData);
            handleClose()
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <Typography>Платежные данные</Typography>
                    <CloseIcon onClick={handleClose} className={classes.closeIcon} />
                </div>
                <Divider />
                <div className={classes.content}>
                    <div className={classes.contentItem}>
                        <Typography>
                            <Box color={errors.account_number ? 'error.main' : 'text.secondary'}>Номер счета</Box>
                        </Typography>
                        <TextField
                            error={errors.account_number}
                            fullWidth
                            name="account_number"
                            type="number"
                            onChange={onChange}
                            value={paymentData.account_number}
                        />
                    </div>
                    <div className={classes.contentItem}>
                        <Typography>
                            <Box color={errors.card_number ? 'error.main' : 'text.secondary'}>Номер карты</Box>
                        </Typography>
                        <TextField
                            error={errors.card_number}
                            fullWidth
                            name="card_number"
                            type="number"
                            onChange={onChange}
                            value={paymentData.card_number}
                        />
                    </div>
                    <div className={classes.contentItem}>
                        <Typography>
                            <Box color={errors.bik ? 'error.main' : 'text.secondary'}>БИК Банка</Box>
                        </Typography>
                        <TextField
                            error={errors.bik}
                            fullWidth
                            name="bik"
                            type="number"
                            onChange={onChange}
                            value={paymentData.bik}
                        />
                    </div>
                    <div className={classes.contentItem}>
                        <Typography>
                            <Box color={errors.full_name ? 'error.main' : 'text.secondary'}>ФИО</Box>
                        </Typography>
                        <TextField
                            error={errors.full_name}
                            fullWidth
                            name="full_name"
                            onChange={onChange}
                            value={paymentData.full_name}
                        />
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button color="secondary" size="large" variant="contained" onClick={handleClose}>Отменить</Button>
                    <Button color="primary" size="large" variant="contained" onClick={handleSave}>Сохранить</Button>
                </div>
            </div>
        </Modal>
    )
}

export default PaymentDetailsModal;
