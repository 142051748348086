export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const sources = {
    googleMap: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=ru&region=RU`,
};

const loadScript = (name, callback) => {
    const id = `${name}Script`;
    const existingScript = document.getElementById(id);

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = sources[name];
        script.id = id;
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) {
                callback();
            }
        };
    } else if (callback) {
        callback();
    }
};

export default loadScript;
