import React, {useMemo} from "react";
import Attach from "@material-ui/icons/AttachFile";
import Record from "@material-ui/icons/KeyboardVoice";
import ButtonBase from "@material-ui/core/ButtonBase";
import moment from "moment";
import TelegramIcon from "@material-ui/icons/Telegram";

import {getChat, deleteChat, sentAudio, sentFile, deleteChatMessage} from "../../actions";
import useSockets from "../../utils/WebSocketProvider";
import useStyles from "./styles";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import IsolatedMenu from "../../components/IsolatedMenu";
import {Link} from "react-router-dom";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const options = ["Удалить"];

const token = localStorage.getItem("token")

export default function ({ history, location, ...props }) {
  const [chatData, setChatData] = React.useState({});
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [socket, setSocket] = React.useState("");
  const [quantity, setQuantity] = React.useState(10);
  const [recording, setRecording] = React.useState(false);
  const [recorder, setRecorder] = React.useState(null);
  const chatRef = React.useRef();
  const classes = useStyles();

  const user_id = useMemo(() => {
    return parseJwt(token).user_id
  }, [token])

  const onMessage = (data) => {
    const newMessages = JSON.parse(data.data);
    if (newMessages?.messages) {
      setMessages(newMessages.messages.reverse());
    } else {
      setMessages((x) => {
        return x
          .concat([newMessages.message])
          .sort((a, b) => new Date(a).getDate() > new Date(b).getDate());
      });
    }
  };

  React.useEffect(() => {
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages.length]);

  const onLoadMessages = () => {
    chatRef.current.scrollTo({ top: 10, behavior: "smooth" });
    setQuantity((x) => x + 10);
  };

  const onGetChat = async () => {
    const chat_id = location.pathname.replace(/\D/g, "");
    const { success, result } = await getChat(chat_id);
    if (success) {
      setChatData(result);
    }
    return result;
  };

  const onGetMessages = () => {
    const chat_id = location.pathname.replace(/\D/g, "");
    socket.send(
      JSON.stringify({
        command: "fetch_messages_from_group_chat",
        quantity,
        chat_id: Number(chat_id),
        user_id,
      })
    );
  };

  React.useEffect(() => {
    if (socket.readyState === 1) {
      onGetMessages();
      voiceRecorderInit();
    }
  }, [socket, quantity]);

  const initSocket = async (newSocket) => {
    const chat_id = location.pathname.replace(/\D/g, "");
    await onGetChat();
    newSocket.onmessage = onMessage;
    newSocket.onopen = () => {
      newSocket.send(
        JSON.stringify({
          command: "fetch_messages_from_group_chat",
          quantity,
          chat_id: Number(chat_id),
          user_id,
        })
      );
    };
    setSocket(newSocket);
    return () => newSocket.close();
  };

  React.useEffect(() => {
    const chat_id = location.pathname.replace(/\D/g, "");
    const newSocket = useSockets({
      chat_id: Number(chat_id),
      type: chatData.chatType || "group",
    });
    initSocket(newSocket);
  }, []);

  const onSentAudio = async (audio_message) => {
    const chat_id = location.pathname.replace(/\D/g, "");
    const data = new FormData();
    data.append("group_chat", chat_id);
    data.append("audio_message", audio_message, "audio_message.wav");
    const res = await sentAudio(data);
    return res.result;
  };

  const onSentFile = async ({ target: { files } }) => {
    if (files && files.length) {
      const chat_id = location.pathname.replace(/\D/g, "");
      const data = new FormData();
      data.append("group_chat", chat_id);
      data.append("attachment", files[0]);
      const { result } = await sentFile(data);
      socket.send(
        JSON.stringify({
          command: "new_attachment_group_chat",
          from: user_id,
          chat_id: Number(chat_id),
          attachment_message_id: result.id,
        })
      );
    }
  };

  const voiceRecorderInit = () => {
    const chat_id = location.pathname.replace(/\D/g, "");
    let rec,
      audioChunks = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        rec = new MediaRecorder(stream);
        rec.ondataavailable = async (e) => {
          audioChunks.push(e.data);
          if (rec.state === "inactive") {
            let blob = new Blob(audioChunks, { type: "audio/x-wav" });
            const res = await onSentAudio(blob);
            socket.send(
              JSON.stringify({
                command: "new_audio_message_group_chat",
                from: user_id,
                chat_id: Number(chat_id),
                audio_message_id: res.id,
              })
            );
            audioChunks = [];
          }
        };
        setRecorder(rec);
      })
      .catch(() => {
        alert("Пожалуйста, разрешите использование микрофона");
      });
  };

  const onClickRecord = () => {
    if (recorder) {
      setRecording((x) => {
        if (!x) {
          recorder.start();
        } else {
          recorder.stop();
        }
        return !x;
      });
    } else {
      voiceRecorderInit();
    }
  };

  const onEnter = ({ keyCode }) => {
    if (keyCode === 13) {
      onSendMessage();
    }
  };

  function onSendMessage() {
    const chat_id = location.pathname.replace(/\D/g, "");
    socket.send(
      JSON.stringify({
        command: "new_message_group_chat",
        from: user_id,
        chat_id: Number(chat_id),
        message,
      })
    );
    setMessage("");
  }

  const onChange = ({ target: { value } }) => {
    setMessage(value);
  };

  const onEdit = () => {
    const chat_id = location.pathname.replace(/\D/g, "");
    history.push(`edit/${chat_id}`);
  };

  const onDelete = async () => {
    const chat_id = location.pathname.replace(/\D/g, "");
    const { success } = await deleteChat(chat_id);
    if (success) {
      history.push("/dashboard/chats");
    }
  };

  const handleMoreAction = async (action, id) => {
    switch (action) {
      case "Удалить":
        const updatedMessages = messages.filter(
          (item) => item.message_id !== id
        );
        await deleteChatMessage([id])
        setMessages(updatedMessages);
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.paper}>
      <ArrowBack
        className={classes.arrowBack}
        onClick={() => {
          history.goBack();
        }}
      />
      <div className={classes.chatWrapper}>
        <div className={classes.chatContent} ref={chatRef}>
          <div className={classes.loadMessages} onClick={onLoadMessages}>
            Загрузить сообщения
          </div>
          {messages?.length ? (
            messages.map((message) => (
              <div
                key={message.created_at}
                style={{...(!(message.user_id === user_id) && !message.is_read_manager && {background: 'rgba(243, 214, 70, 0.27)'})}}
                  className={
                  message.user_type === "manager"
                    ? classes.ownMessage
                    : classes.commonMessage
                }
              >
                <div className={classes.messageTitle}>
                  <Link to={`/dashboard/users/${message.user_id}`}>
                    <div className={classes.messageUser}>
                      <img
                        src={message.avatar_url}
                        className={classes.messageUserIcon}
                        alt=""
                      />
                      <div className={classes.messageUserName}>
                        {message.user_type === "manager" ? "Manager" : message.author || ""}
                      </div>
                    </div>
                  </Link>

                  <div className={classes.rightContainer}>
                    <p className={classes.messageDate}>
                      {moment(message.created_at).format("DD MMMM YYYY, hh:mm")}
                    </p>
                    {localStorage.getItem('type') === "manager" && (
                     <IsolatedMenu options={options} onClick={option => handleMoreAction(option, message.message_id)} />
                    )}
                  </div>
                </div>
                <div className={classes.messageContent}>
                  {message.content ? <p>{message.content}</p> : null}
                  {message.audio_url ? <audio controls src={message.audio_url} /> : null}
                  {message.attachment_url ? (
                    <img src={message.attachment_url} alt="attachment" />
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>Нет сообщения</p>
          )}
        </div>
        <div className={classes.chatActions}>
          <div className={classes.chatActionsAttach}>
            <Attach />
            <input
              type="file"
              accept="image/x-png,image/jpeg, image/jpg"
              className={classes.attachment}
              onChange={onSentFile}
            />
          </div>
          <div className={classes.chatActionsInput}>
            <input
              cols="50"
              onKeyUp={onEnter}
              disabled={recording}
              onChange={onChange}
              value={message || ""}
            />
          </div>
          {message.length ? (
            <div onClick={onSendMessage} className={classes.chatActionsRecord}>
              <TelegramIcon />
            </div>
          ) : (
            <div
              className={
                recording
                  ? classes.chatActionsRecordActive
                  : classes.chatActionsRecord
              }
              onClick={onClickRecord}
            >
              <Record />
            </div>
          )}
        </div>
      </div>
      <div className={classes.sidebar}>
        <div
          className={classes.sidebarLogo}
          style={{ backgroundImage: `url(${chatData.image})` }}
        />
        <div className={classes.sidebarTitle}>{chatData?.name}</div>
        <div className={classes.sidebarUserCount}>
          {chatData?.active_participants?.length} пользователя
        </div>
        <div className={classes.sidebarBlockedCount}>
          {chatData?.blocked_participants?.length || 0} заблокированных
        </div>
        <div className={classes.sidebarActions}>
          <ButtonBase onClick={onEdit}>Изменить</ButtonBase>
          <ButtonBase onClick={onDelete}>Удалить</ButtonBase>
        </div>
      </div>
    </div>
  );
}
