import React, { useState } from 'react';
import { TextField, Button, Stepper, Step, StepLabel,StepConnector, Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import useStyles from './styles';
import { createTaxopark } from '../../actions';

const placeholders = {
    name: "Название компании",
    contact_phone_number: "Контактный номер телефона",
    inn: "ИНН",
    contact_email: "Контактный e-mail",
    owner_phone_number: "Номер телефона директора",
    owner_patronymic: "Отчество",
    owner_first_name: "Имя",
    owner_last_name: "Фамилия",
    owner_email:"E-mail  директора",
};

const StyledTextField = styled(({ hasContent, isError, ...otherProps }) => <TextField {...otherProps} />)`
    margin-top: 26px;
    margin-bottom: 4px;
    width:50%;
    & .MuiOutlinedInput-root {
        border-radius: 8px;

        & fieldset {
            border: 1px solid ${({ hasContent }) => hasContent ? '#FFB500' : '#CCCCCC'};
        }

        &:hover fieldset {
            border: 1px solid #FFB500 !important;  // Maintain hover border color
        }

        &:focus-within fieldset {
            border: 1px solid #FFB500;  // Border color when focused
        }
    }

    & .MuiFormHelperText-root {
        color: ${({ isError }) => isError ? 'red' : 'inherit'}; // Change helper text color based on error
    }

    & input {
        padding: 15.5px 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: ${({ hasContent }) => hasContent ? '#757575' : 'CurrentColor'};
    }

    & .MuiInputLabel-outlined {
        transform: translate(22px, 15.5px) scale(1);
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(22px, -6px) scale(0.75);
    }
`;
const StyledButton = styled(({ disabled, ...otherProps }) => <Button {...otherProps} />)`
    background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
    color: ${({ disabled }) => disabled ? '#FBFBFB' : '#FFFFFF'};
    box-shadow:${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    border-radius:8px;
    height:50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size:16px;
    &:hover {
        background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
        box-shadow:${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    }
`;
const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // margin-bottom: 16px;

    & > div {
        flex: 1;  // Makes each child div take up equal space
        &:first-child {
            margin-right: 60px;  // Adds a gap between the two fields
        }
    }
`;
const CustomStepConnector = styled(StepConnector)`
  display: none;  // Hide the connector lines between steps
`;

const CustomStepper = styled(Stepper)`
  background-color: transparent;
  padding: 8px 0;
  justify-content:center;
  & .MuiStep-horizontal{
    padding:0;
  }
  & .MuiStepLabel-iconContainer{
    padding-right:12px;

  }
`;

const StepIcon = styled.div`
  width: ${({ active, completed }) => active ? '70px' : completed ? '30px' : '30px'};
  height: 10px;
  background-color: ${({ active, completed }) => active ? '#FFB500' : completed ? '#FFB500' : '#EEEEEE'};
  border-radius: 5px;
  padding:0 !important;
`;

const StyledSnackbar = styled(Snackbar)`
    & .MuiSnackbarContent-root {
        background-color: #FFB500;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size:16px;
    }
`;

export default function ({ history }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [formData, setFormData] = useState({
        name: "",
        contact_phone_number: "",
        inn: "",
        contact_email: "",
        owner_phone_number: "",
        owner_patronymic: "",
        owner_first_name: "",
        owner_last_name: "",
        owner_email: ""
    });
    const [errors, setErrors] = useState({});

    const steps = ['Contact Info', 'Owner Info'];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateInput(name, value);
    };
    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    
    const validateInput = (name, value) => {
        let error = "";
        switch (name) {
            case 'contact_phone_number':
            case 'owner_phone_number':
                // if (!/^\+\d{12}$/.test(value)) {
                //     error = "Phone number must be in +123456789012 format.";
                // }
                break;
            case 'contact_email':
                if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "Invalid email address.";
                }
                break;
            case 'owner_email':
                if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "Invalid email address.";
                }
                break;
            case 'inn':
                // if (!/^\d{3}-\d{3}-\d{12}$/.test(value)) {
                //     error = "INN must be in 123-456-123456789012 format.";
                // }
                break;
            default:
                if (!value.trim()) {
                    error = "This field is required.";
                }
                break;
        }
        setErrors(prev => ({ ...prev, [name]: error }));
    };

    const stepContent = (step) => {
        switch (step) {
            case 0:
                return ["name", "contact_phone_number", "inn", "contact_email"];
            case 1:
                return ["owner_first_name", "owner_last_name", "owner_patronymic", "owner_phone_number","owner_email"];
            default:
                throw new Error('Unknown step');
        }
    };

    const currentFields = stepContent(activeStep);
    const hasError = currentFields.some(field => errors[field]);
    const isEmpty = currentFields.some(field => !formData[field]);

    const handleNext = async () => {
        if (hasError || isEmpty) {
            console.log('Form validation failed');
            return;
        }

        if (activeStep === steps.length - 1) {
            handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleSubmit = async () => {
        const response = await createTaxopark(formData);
        if (response && response.success) {
            console.log('Taxopark created successfully:', response);
            history.push('/dashboard');
        } else {
            handleSnackbarOpen(response.message);
            console.error('Failed to create taxopark:', response);
        }
    };

    return (
        <React.Fragment>
            <div style={{height: 'calc(100vh - 158px)',display:'flex',flexDirection:'column'}}>
            <CustomStepper activeStep={activeStep} connector={<CustomStepConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={() => <StepIcon   active={activeStep === index} completed={activeStep > index} />} />
                        </Step>
                    ))}
                </CustomStepper>
            <div className={classes.textContainer}>
                <div className={classes.text}>Пожалуйста, заполните все поля.</div>
                <div className={classes.subText}>После того, как вы заполните данные на текущей странице и убедитесь, что все обязательные поля заполнены правильно, вы нажимаете кнопку "Далее", чтобы перейти на следующую страницу.</div>
            </div>
            <form className={classes.form}>
                <div>
                {currentFields.map((key, index) => {
    // Group fields in pairs
    if (index % 2 === 0) { // Check if the index is even, to start a new FlexContainer
        let nextKey = currentFields[index + 1]; // The next field to pair with
        return (
            <FlexContainer key={index}>
                <StyledTextField
                    type="text"
                    placeholder={placeholders[key]}
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ style: { color: '#949494', } }}
                    hasContent={Boolean(formData[key])}
                />
                {nextKey ? (
                    <StyledTextField
                        type={nextKey.includes('password') ? 'password' : 'text'}
                        placeholder={placeholders[nextKey]}
                        name={nextKey}
                        value={formData[nextKey]}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ style: { color: '#949494',textTransform: 'none' } }}
                        hasContent={Boolean(formData[nextKey])}
                    />
                ) : (
                    // Render a hidden dummy input if there is no nextKey
                    <div></div>
                    // <StyledTextField
                    //     style={{ display: 'none' }} // This makes the input invisible
                    //     type="text"
                    //     variant="outlined"
                    //     margin="normal"
                    // />
                )}
            </FlexContainer>
        );
    }
    return null; // Skip rendering here for odd indices
})}
                </div>
                <StyledButton
                onClick={handleNext}
                fullWidth
                variant="contained"
                disabled={hasError || isEmpty}
                >
                    {activeStep === steps.length - 1 ? 'Зарегистрировать' : 'Далее'}
                </StyledButton>
            </form>
            </div>
            <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ top: 120 }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
        />
        </React.Fragment>
    );
}
