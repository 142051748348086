import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const IsolatedMenu = ({ options, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemPress = (option) => {
    onClick(option);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          padding: 0,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={open}
        id="long-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleItemPress(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default IsolatedMenu;
