import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  addButtonWrapper: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: theme.spacing(0, 2),
    zIndex: 10
  },
  addButtonWrapperFab: {
    margin: theme.spacing(2),
    color: 'white',
    backgroundColor: '#3f51b5',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: '#3f61b9',
    }
  },
}))
