import React from 'react';
import moment from 'moment';

import FiltersModal from '../../components/FiltersModal';
import SearchBox from '../../components/TableSearch';
import AddButton from '../../components/AddButton';
import Table from '../../components/Table';
import { debounce, CHATS_FILTERS } from '../../utils';
import { getChatList } from '../../actions';
// import supportImage from '../../assets/support.png'

function parseData(data) {
  return ({
    headers: [
      { dataAccessor: 'image', title: '', sortable: false },
      { dataAccessor: 'name', title: 'Название чата', sortable: true },
      { dataAccessor: 'group_type', title: 'Тип чата', sortable: true },
      { dataAccessor: 'participants_qty', title: 'Количество пользователей', sortable: true },
      { dataAccessor: 'created_at', title: 'Создан', sortable: true },
      { dataAccessor: 'last_message_datetime', title: 'Последнее сообщение', sortable: true },
    ],
    body: data.map(x => ({
      id: x.id,
      image: x.image,
      name: x.name,
      group_type: x.group_type === 'group' ? 'Групповой' : x.group_type === 'sos' ? 'Черезвычайные ситуации' : 'Служба поддержки',
      participants_qty: x.participants_qty,
      created_at: moment(x.created_at).format('DD MMMM YYYY HH:MM'),
      isNew: !x.is_read_manager,
      last_message_datetime: moment(x.last_message_datetime || '-', 'DD MM YYYY HH:mm').format('DD MMMM YYYY HH:mm').replace('Invalid date', '-'),
    })),
  });
}

export default function ({ history }) {
  const [tableData, setTableData] = React.useState(parseData([]));
  const [filtersIsOpen, setFiltersIsOpen] = React.useState(false);
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [filtersData, setFiltersData] = React.useState({});

  const onGetChatsList = async ({ page, search, ordering, ...filters }) => {
    setFiltersData({ page, search, ...filters });
    const { success, results, count } = await getChatList({
      page,
      search,
      filters,
      ordering
    });
    if (success) {
      setTableData(parseData(results));
      setPageData({ page, count });
    }
  }
  React.useEffect(() => {
    onGetChatsList({ page: 1 });
  }, []);
  const onChangePage = (e, newPage) => {
    onGetChatsList({ ...filtersData, page: newPage + 1, });
  }
  const onClearFilters = () => {
    onGetChatsList({ page: 1 });
  }

  const onClickFilters = () => setFiltersIsOpen(true);
  const onCloseFilters = () => setFiltersIsOpen(false);
  const onClickAdd = () => history.push('chats/create');
  const debouncedGetChatsList = debounce(500, onGetChatsList);
  const handleClickRow = (values) => history.push(`chats/${values.id}`);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedGetChatsList({ page: 1, ...filtersData, search: value })
  }
  const onSave = (values) => {
    const vals = {
      page: 1,
      ...values,
    }
    debouncedGetChatsList(vals)
    setFiltersIsOpen(false);
  };

  const onChangeSort = (name) => {
    onGetChatsList({ page: 1, ordering: name });
  }

  return (
    <React.Fragment>
      <SearchBox
        resultCount={tableData.body.length}
        onChangeSearch={onChangeSearch}
        onClickFilters={onClickFilters}
        onClearFilters={onClearFilters}
        filtersData={filtersData}
        haveFilters={true}
      />
      <Table
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
        onSort={onChangeSort}
      />
      <FiltersModal
        isOpen={filtersIsOpen}
        onSave={onSave}
        onClose={onCloseFilters}
        fields={CHATS_FILTERS}
        fieldsData={filtersData}
      />
      <AddButton onClick={onClickAdd} />
    </React.Fragment>
  );
};
