import { makeStyles } from '@material-ui/core/styles';


export default makeStyles(theme => ({
    textContainer:{
        marginTop:'34px',
        marginBottom:'55px',
        display:'flex',
        flexDirection:'column',
        gap:'15px',
    },
    text:{
        fontSize:'16px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        lineHeight:'19.2px',
        color:'#484745'
    },
    subText:{
        width:'70%',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize:'12px',
        lineHeight:'19.2px',
        color:'#949494'
    },
    form:{
        height:'100%',
        marginBottom:'41px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between'
    }
}))
  