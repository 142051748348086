import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid lightgray',
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: 100
  },
  container: {
    // maxHeight: '70vh',
  },
  header: {
    display: 'flex',
    // justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableCell: {
    '&:hover': {
      '& svg': {
        opacity: 1
      }
    }
  },
  sort: {
    transition: 'all .3s ease-in',
    opacity: 0,
  },
  asc: {
    transform: 'rotate(180deg)'
  },
  done: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: 'white',
      width: 20,
      height: 20,
    }
  },
  table: {
    '& tr': {
      '&:nth-child(odd)': {
        background: '#f4f4f4'
      },
      cursor: 'pointer',
      '&:hover': {
        background: '#f4f4f4'
      },
      '&>th': {
        '&:last-child': {
          '& > div': {
            justifyContent: 'flex-end',
            '& > p': {
              textAlign: 'right'
            }
          }

        }
      },
      '&>td': {
        '&:last-child': {
          textAlign: 'right'
        }
      }
    },
  },
  avatar: {
    width: 24,
    height: 24,
    position: 'relative'
  },
  support: {
    backgroundColor: 'orange',
    '& img': {
      width: 17,
      height: 15,
    }
  },
  pictureCell: {
    width: 30,
  },
}));

export const usePaginationStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  pages: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pagination: {
    display: 'flex'
  },
  toolbar: {
    paddingLeft: 0
  },
  page: {
    padding: '0 5px',
    borderRadius: '4px',
    margin: 'auto 5px',
    textAlign: 'center',
    cursor: 'pointer',
    color: 'grey'
  },
  activePage: {
    padding: '0 5px',
    borderRadius: '4px',
    margin: 'auto 5px',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));
