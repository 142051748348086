import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles((theme) => ({ }));

export default createMuiTheme({
  palette: {
    type: 'light',
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiPaper: {
      root: {
        minWidth: 460,
        boxShadow: 'none',
      }
    },
    MuiTextField: {
      root: {
        outline: 'none'
      },
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'grey',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'grey',
          '@media (hover: none)': {
            borderColor: 'grey',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: 'grey',
          color: 'black',
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#000'
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
      }
  }
});
