import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>  ({
    wrapper: {
      position: 'absolute',
      width: '70%',
      padding: '20px 25px',
      borderRadius: 4,
      overflow: 'auto',
      backgroundColor: '#fff',
      margin: 'auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    contentItem: {
      flex: 1,
      '& > div': {
        margin: '0 0 20px 0',
      }
    },
    formControl: {
      width: '100%'
    },
    greyText: {
      color: 'grey'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 40,
    },
    content: {
      display: 'flex',
      marginBottom: 40,
      overflow: 'scroll',
      maxHeight: '60vh',
      justifyContent: 'space-between'
    },
    image: {
      width: 300,
      height: 300,
      borderRadius: 4,
      backgroundSize: 'cover',
      backgroundColor: '#999',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      margin: 'auto',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        boxShadow: 'none',
        color: '#fff',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:last-child': {
          marginLeft: 27,
        },
      }
    },
    closeIcon: {
      cursor: 'pointer'
    },
    rangeInputWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      '& input': {
        flex: 1,
        width: '100%',
      },
      '&>div:first-child': {
        marginRight: 20
      },
      '&>div:last-child': {
        marginLeft: 20
      }
    },
    flex: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& p': {
        width: '100%',
      },
      '& div': {
        width: 300,
      }
    }
  })
);
