import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Photo from "@material-ui/icons/PhotoCameraOutlined";

import { updatePartner, createPartner } from "../../actions";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import { isUrlValid } from "../../utils";

function EnhancedModal({ isOpen, onClose, data, isEdit }) {
  const [userData, setUserData] = useState({
    name: data.name || "",
    image: data.image || "",
    description: data.description || "",
    url: data.url || "",
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    url: false,
  });

  const classes = useStyles();

  React.useEffect(() => {
    setUserData({
      name: data.name || "",
      image: data.image || "",
      description: data.description || "",
      url: data.url || "",
    });
  }, [data]);

  const onSave = async () => {
    const formData = new FormData();
    formData.append("name", userData.name);
    userData.file && formData.append("image", userData.file);
    formData.append("description", userData.description);
    formData.append("created_at", new Date().toISOString());
    formData.append("url", userData.url);

    if (
      !Object.keys(userData)
        .map((v) => {
          if (v === "file" ? !userData.image.length : !userData[v].length) {
            setErrors((errors) => {
              return { ...errors, [v]: true };
            });
          }
          return v === "image" || v === "file" || !!userData[v].length;
        })
        .includes(false)
    ) {
      if (isEdit) {
        const { success } = await updatePartner(formData, data.id);
        if (success) {
          onClose();
        }
      } else {
        const { success } = await createPartner(formData);
        if (success) {
          onClose();
        }
      }
    }
  };

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setUserData({
        ...userData,
        image: URL.createObjectURL(target.files[0]),
        file: target.files[0],
      });
    }
  };

  const onChange = ({ target: { value, name } }) => {
    setUserData({ ...userData, [name]: value });

    if (name === "url" && !isUrlValid(value)) {
      setErrors({ ...errors, url: true });
      return;
    }
    setErrors({ ...errors, [name]: false });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Данные о партнере</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItemWithMap}>
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${userData.image})` }}
            />
            <input
              type="file"
              name="picture"
              className={classes.input}
              onChange={onChangeImage}
            />
            <div className={classes.photoWrapper}>
              <Photo className={classes.photo} />
            </div>
          </div>
          <div className={classes.contentItem}>
            <div>
              <Typography>
                <Box color={errors.name ? "error.main" : "text.secondary"}>
                  Название
                </Box>
              </Typography>
              <TextField
                error={errors.name}
                fullWidth
                name="name"
                multiline
                onChange={onChange}
                value={userData.name}
              />
            </div>
            <div>
              <Typography>
                <Box
                  color={errors.description ? "error.main" : "text.secondary"}
                >
                  Описание
                </Box>
              </Typography>
              <TextField
                error={errors.description}
                fullWidth
                multiline
                name="description"
                onChange={onChange}
                value={userData.description}
              />
            </div>
            <div>
              <Typography>
                <Box color={errors.url ? "error.main" : "text.secondary"}>
                  Ссылка
                </Box>
              </Typography>
              <TextField
                error={errors.url}
                fullWidth
                multiline
                name="url"
                onChange={onChange}
                value={userData.url}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onSave}
          >
            {isEdit ? "Сохранить изменения" : "Добавить партнера"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EnhancedModal;
