import React from 'react';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import loginTaxi from "../../assets/loginTaxi.jpg"
// import AppBar from '@material-ui/core/AppBar';

import {signIn} from '../../actions';

const StyledTypography = styled(Typography)`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color:#121212;
`;

const Container = styled.div`
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  margin: 0 auto 13px auto;
  font-weight: bold;
`;

const ForgotPassword = styled.div`
  margin-top: 40px;
  margin-bottom:20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledPaper = styled(Paper)`
  background-color: transparent;
  box-shadow:none;
`;

const StyledTextField = styled(TextField)`
    margin-top: 16px;
    margin-bottom: 4px;
    & .MuiOutlinedInput-root {
        border-radius:8px;
        & fieldset {
            border: 1px solid #CCCCCC;
        }

        &:hover fieldset {
            border: 1px solid #CCCCCC !important;
        }

        &.Mui-focused fieldset {
            border: 1px solid #CCCCCC;
        }
    }
    & input {
        padding:15.5px 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }
    & .MuiInputLabel-outlined {
        transform: translate(22px, 15.5px) scale(1);
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(22px, -6px) scale(0.75);
    }
`;
const ResponsiveImage = styled.img`
  width: 50%;
  height: 100vh;
  object-fit: contain;
  object-position: left;

  @media (max-width: 1000px) {
    display: none; 
  }
`;
const ResponsiveGrid = styled(Grid)`
  min-height: 100vh;
  height: 100%;
  padding-right: 162px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content:space-between;
  @media (max-width: 1250px) {
  padding-right: 50px;
  }
  @media (max-width: 1000px) {
    justify-content: center;
    padding-right: 0px;
  }
`;

export default ({history}) => {
    const [values, changeValues] = React.useState({login: '', password: '', error: false});

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard');
        }
    }, [history])

    const onChange = ({target: {value, id}}) => {
        changeValues({...values, error: false, [id]: value});
    }

    const onSignIn = async (data) => {
        const {success, result, type, id} = await signIn(data);
        if (success) {
            localStorage.setItem('token', result)
            localStorage.setItem('type', type)
            localStorage.setItem('id', id)
            history.push('/dashboard');
        } else {
            changeValues({...values, password: '', error: true})
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (values.login && values.password) {
            onSignIn({phone_number: values.login, password: values.password})
        }
    }

    return (
        <React.Fragment>
            {/* <AppBar position="static">
                <Toolbar>
                    <StyledTypography align="center">Ш-а-ш-ка</StyledTypography>
                </Toolbar>
            </AppBar> */}
             <ResponsiveGrid
                container
                spacing={0}
                alignItems="center"
            >
                <ResponsiveImage  src={loginTaxi} alt="loginTaxi-image"/>
                <StyledPaper onSubmit={handleSubmit} component="form" >
                    <Container>
                        <Title>
                            <StyledTypography>Авторизация</StyledTypography>
                        </Title>
                        <StyledTextField
                            type="text"
                            id="login"
                            name="login"
                            label="Логин"
                            dataaccessor="data-login"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={values.login}
                            onChange={onChange}
                            margin="dense"
                            InputLabelProps={{
                                style: { color: '#949494' },
                            }}
                        />
                        <StyledTextField
                            type="password"
                            id="password"
                            name="password"
                            label="Пароль"
                            dataaccessor="data-password"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={values.password}
                            onChange={onChange}
                            margin="dense"
                            InputLabelProps={{
                                style: { color: '#949494' },
                            }}
                        />
                        <div>
                            {values.error ? (
                                <Typography color="error">
                                    Не правильный номер или пароль
                                </Typography>
                            ) : null}
                        </div>
                        <ForgotPassword>
                            <Typography style={{color:'#484745',fontFamily: 'Montserrat',fontWeight: 600}}>
                                <Link to="/forgot-password">Забыли пароль?</Link>
                            </Typography>
                            <Typography style={{color:'#484745',fontFamily: 'Montserrat',fontWeight: 600}}>
                                <Link to="/forgot-password">Нужна помощь?</Link>
                            </Typography>
                        </ForgotPassword>
                        <Button variant="contained" style={{ backgroundColor: '#FFB500', color: '#121212',fontSize:'16px', fontFamily: 'Montserrat',fontWeight: 500,height:'50px',boxShadow: '1px 1px 4px 0px #00000026',borderRadius:'8px' }}  fullWidth type="submit">Войти</Button>
                    </Container>
                </StyledPaper>
            </ResponsiveGrid>
        </React.Fragment>
    )
} 
