import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowBack from "@material-ui/icons/ArrowBackIos";

import EditFAQModal from "../../components/EditFAQModal";
import { getFAQ, deleteFAQ, getFAQTags } from "../../actions";
import useStyles from "./styles";

const selection = {
  about_service: "О сервисе",
  about_partners: "О партнерах",
  about_payments: "Об оплате",
};

export default ({ location, history }) => {
  const classes = useStyles();
  const [data, setData] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [editFAQData, seteditFAQData] = React.useState({
    isOpen: false,
    data: {},
  });

  const onGetNews = React.useCallback(async () => {
    const dataId = location.pathname.replace(/\D/g, "");
    const { success, result } = await getFAQ(dataId);
    if (success) {
      setData(result);
    }
  }, [location.pathname]);

  const onGetCategories = async () => {
    const { success, results } = await getFAQTags();
    if (success) {
      setCategories(results.map(({ id, name }) => ({ id, type: name, name })));
    }
  };

  React.useEffect(() => {
    onGetNews();
    onGetCategories();
  }, []);

  const onEdit = () => {
    seteditFAQData({
      isOpen: true,
    });
  };

  const onClose = () => {
    seteditFAQData({
      isOpen: false,
    });
    onGetNews();
  };

  const onDelete = async () => {
    await deleteFAQ(data.id);
    history.push("/dashboard/faq");
  };

  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <div className={classes.imageDescription}>
          <ArrowBack
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>FAQ</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div>
              <span>Категория</span>
              <Typography>{data.tag_name}</Typography>
            </div>
            <div>
              <span>Заголовок</span>
              <Typography>{data.title}</Typography>
            </div>
            <div>
              <span>Текст</span>
              <Typography>{data.description}</Typography>
            </div>
          </div>
          <div className={classes.actions}>
            <ButtonBase onClick={onEdit}>Изменить</ButtonBase>
            <ButtonBase onClick={onDelete}>Удалить</ButtonBase>
          </div>
        </div>
      </div>
      <EditFAQModal
        isEdit={true}
        isOpen={editFAQData.isOpen}
        data={data || {}}
        onClose={onClose}
        categories={categories}
      />
    </div>
  );
};
