import React from 'react';
import moment from 'moment';

import FiltersModal from '../../components/FiltersModal';
import SearchBox from '../../components/TableSearch';
import AddButton from '../../components/AddButton';
import Table from '../../components/Table';
import { debounce, USER_FILTERS } from '../../utils';
import { getUsersList } from '../../actions';

function parseData(data) {
  return ({
    headers: [
      { dataAccessor: 'picture', title: '', sortable: false },
      { dataAccessor: 'fullName', title: 'ФИО', sortable: true },
      { dataAccessor: 'phone_number', title: 'Телефон', sortable: true },
      { dataAccessor: 'city', title: 'Город', sortable: true },
      { dataAccessor: 'driver_status', title: 'Статус', sortable: true },
      { dataAccessor: 'last_login', title: 'Последнее обновление', sortable: true },
    ],
    body: data.map(x => ({
      id: x.id,
      picture: x.picture,
      fullName: `${x.last_name || '-'} ${x.first_name || '-'} ${x.patronymic || '-'}`,
      phone_number: x.phone_number || '-',
      city: x.city || '-',
      driver_status: x.driver_status,
      last_login: x.last_login ? moment(x.last_login).format('DD MMMM YYYY HH:MM') : '-',
      isNew: x.is_new_user
    }))
  });
}

export default function ({ history }) {
  const [filtersIsOpen, setFiltersIsOpen] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState({});
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));

  const onGetUsersList = async ({ page, search, ordering, ...filters }) => {
    setFiltersData({ page, search, ...filters });
    const { success, results, count } = await getUsersList({
      page,
      search,
      filters,
      ordering
    });

    if (success) {
      setTableData(parseData(results))
      setPageData({ page, count });
    }
  }

  React.useEffect(() => {
    onGetUsersList({ page: 1 });
  }, []);

  const onClearFilters = () => {
    onGetUsersList({ page: 1 })
  };
  const onChangePage = (e, newPage) => {
    onGetUsersList({ ...filtersData, page: newPage + 1, });
  }
  const onCloseFilters = () => setFiltersIsOpen(false);
  const onClickFilters = () => setFiltersIsOpen(true);
  const debouncedUsersGet = debounce(500, onGetUsersList);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedUsersGet({ page: 1, search: value })
  }
  const onSave = (values) => {
    const vals = {
      page: 1,
      ...values,
    }
    debouncedUsersGet(vals);
    setFiltersIsOpen(false);
  };
  const onClickAdd = () => { };
  const handleClickRow = (values) => history.push(`users/${values.id}`);


  function handleSort(name) {
    onGetUsersList({ page: 1, ordering: name });
  }
  console.log(tableData,'tableData')
  return (
    <React.Fragment>
      <SearchBox
        resultCount={tableData.body.length}
        onChangeSearch={onChangeSearch}
        onClickFilters={onClickFilters}
        onClearFilters={onClearFilters}
        filtersData={filtersData}
        haveFilters={true}
      />
      <Table
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
        onSort={handleSort}
      />
      <FiltersModal
        isOpen={filtersIsOpen}
        onSave={onSave}
        onClose={onCloseFilters}
        fields={USER_FILTERS}
        fieldsData={filtersData}
      />
      {/*<AddButton onClick={onClickAdd} />*/}
    </React.Fragment>
  );
};
