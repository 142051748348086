import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Delete from "@material-ui/icons/Delete";

import moment from "moment";
import EditAddress from "../../components/EditAddress";
import { deleteAddress, deleteAddressComment } from "../../actions";
import useStyles from "./styles";

export default ({
  totalRating,
  picture,
  name,
  placeTitle: placeName,
  address,
  rating,
  createdDate,
  id,
  backToList,
  comments,
}) => {
  const placeTitle = name || placeName;
  const classes = useStyles();
  const [modalData, setModalData] = React.useState({ isOpen: false });
  const [commentsData, setCommentsData] = React.useState(comments);
  const onDelete = async () => {
    await deleteAddress(id);
    backToList();
  };
  const onEdit = () => {
    setModalData({
      isOpen: true,
      data: {
        placeTitle,
        address,
        picture,
        id,
      },
    });
  };
  const onClose = () => {
    setModalData({ isOpen: false });
    backToList();
  };
  const onDeleteComment = (commentId) => async () => {
    const { success } = await deleteAddressComment(id, commentId);
    if (success) {
      setCommentsData(commentsData.filter((x) => x.id !== commentId));
    }
  };
  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${picture})` }}
        />
        <div className={classes.imageDescription}>
          <Typography>{placeTitle}</Typography>
          <Typography>{address}</Typography>
          <div>
            <Typography variant="subtitle2" className={classes.greyText}>
              Создано в
            </Typography>
            <Typography variant="subtitle2">{createdDate}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2" className={classes.greyText}>
              Средняя оценка
            </Typography>
            <Typography variant="subtitle2">{rating || 0}</Typography>
          </div>
          <div className={classes.actions}>
            <ButtonBase onClick={onEdit}>Изменить</ButtonBase>
            <ButtonBase onClick={onDelete}>Удалить</ButtonBase>
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Оценки</Typography>
            <p>{totalRating || 0}</p>
          </div>
          <Divider />
          {commentsData.map((comment) => (
            <div className={classes.userData} key={comment.id}>
              <div className={classes.userDataTitle}>
                <Typography>
                  {moment(comment.created_at).format("hh:mm DD MMMM YYYY")}
                </Typography>
                <Delete
                  className={classes.delete}
                  onClick={onDeleteComment(comment.id)}
                />
              </div>
              <Typography className={classes.text}>{comment.text}</Typography>
              <div className={classes.commentUser}>
                <div className={classes.commentUserTitle}>
                  <img src={comment.commenter.picture} alt="map point" />
                  <Typography>
                    {comment.commenter.first_name} {comment.commenter.last_name}
                  </Typography>
                </div>
                <div className={classes.commentUserRating}>
                  <Typography>Оценка: </Typography>
                  <span>{comment.rating}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <EditAddress
        isOpen={modalData.isOpen}
        onClose={onClose}
        data={modalData.data || {}}
        isEdit={true}
      />
    </div>
  );
};
