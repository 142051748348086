import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        flex: 1,
        marginLeft: 60,
        '& hr': {
            backgroundColor: 'rgba(0, 0, 0, 0.62)',
        }
    },
    wrapperItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    header: {
        '& > p': {
            fontSize: 20,
            margin: 0,
        },
    },
    paper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 4,
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
    },
    sticky: {
        position: 'sticky',
        height: 340,
        display: 'flex',
        top: 0,
        '& > div': {
            marginLeft: 10,
        }
    },
    arrowBack: {
        cursor: 'pointer',
        color: 'gray',
    },
    contentItemWithMap: {
        position: 'relative'
    },
    image: {
        width: 300,
        height: 300,
        borderRadius: 4,
        backgroundSize: 'cover',
        backgroundColor: '#999',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        margin: 'auto',
    },
    input: {
        position: 'absolute',
        width: 300,
        height: 300,
        cursor: 'pointer',
        opacity: 0,
        zIndex: 3,
        top: 0,
    },
    photoWrapper: {
        position: 'absolute',
        zIndex: 2,
        top: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300,
        height: 300,
        cursor: 'pointer',
    },
    photo: {
        width: 50,
        height: 50,
        color: 'white',
    },
    userData: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        flex: 1,
        '& span': {
            color: 'grey',
        },
        '& > div': {
            width: '100%',
            padding: '20px 0'
        }
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            boxShadow: 'none',
            color: '#fff',
            '&:hover': {
                boxShadow: 'none'
            },
            '&:last-child': {
                marginLeft: 27,
            },
        }
    },
    contentItem: {
        marginLeft: 20,
        flex: 1,
        '& p': {
            color: 'grey'
        },
        '& > div': {
            margin: '0 0 20px 0',
        }
    },
    formControl: {
        width: "100%"
    }

}))
