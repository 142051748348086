import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Photo from "@material-ui/icons/PhotoCameraOutlined";

import Map from "../Map";
import { updateAddress, createAddress } from "../../actions";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import SearchPlaces from "./SearchPlaces";
import  {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function EnhancedModal({ isOpen, onClose, data, isEdit }) {
  const [userData, setUserData] = React.useState({
    placeTitle: data.placeTitle || "",
    address: data.address || "",
    picture: data.picture || "",
  });

  const [errorText, setErrorText] = useState('');

  const [errors, setErrors] = useState({
    placeTitle: false,
    address: false,
  });

  const classes = useStyles();

  React.useEffect(() => {
    setUserData({
      ...data,
      placeTitle: data?.placeTitle || "",
      address: data?.address || "",
      picture: data?.picture || "",
    });
  }, [data]);

  const onSave = async () => {
    const formData = new FormData();
    if (
      !Object.keys(errors)
        .map((v) => {
          if (!userData[v].length) {
            setErrors((errors) => {
              return { ...errors, [v]: true };
            });
          }
          return !!userData[v].length;
        })
        .includes(false)
    ) {
      if (isEdit) {
        formData.append("name", userData.placeTitle);
        userData.file && formData.append("image", userData.file);
        formData.append("address", userData.address);
        userData.lngLat &&
          formData.append(
            "point",
            JSON.stringify({
              type: "Point",
              coordinates: [...Object.values(userData.lngLat)],
            })
          );
        await updateAddress(formData, data.id);
      } else {
        formData.append("name", userData.placeTitle);
        userData.file && formData.append("image", userData.file);
        formData.append("address", userData.address);
        userData.lngLat &&
          formData.append(
            "point",
            JSON.stringify({
              type: "Point",
              coordinates: [...Object.values(userData.lngLat)],
            })
          );
        await createAddress(formData);
      }
      onClose();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      return false;
    }
  };

  const onChange = async ({ target: { value, name } }) => {
    setErrors({ ...errors, [name]: false });
    setUserData({ ...userData, [name]: value });
  };

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setUserData({
        ...userData,
        picture: URL.createObjectURL(target.files[0]),
        file: target.files[0],
      });
    }
  };

  const onClick = (e, features) => {
    setUserData({
      ...userData,
      lngLat: e.lngLat,
      address: features[0] ? features[0].place_name || "" : "",
    });
  };



  const handleSelect = address => {
    geocodeByAddress(address)
        .then(results => {
          return getLatLng(results[0])
        })
        .then(latLng => {
          setUserData({
            ...userData,
            address,
            lngLat: {...latLng}
          })
        })
        .catch(error => {
          setErrorText("Местоположение не найдено");
          setUserData({
            ...userData,
            address: '',
            lngLat: {}
          })
          console.log('Error', error)
        });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Данные адреса</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItemWithMap}>
            <div className={classes.logoWrapper}>
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${userData.picture})` }}
              />
              <input
                type="file"
                name="picture"
                className={classes.input}
                onChange={onChangeImage}
              />
              <div className={classes.photoWrapper}>
                <Photo className={classes.photo} />
              </div>
            </div>
            <Map className={classes.mapWrap} onClick={onClick} />
          </div>
          <div className={classes.contentItem}>
            <Typography>
              <Box color={errors.placeTitle ? "error.main" : "text.secondary"}>
                Название места
              </Box>
            </Typography>
            <TextField
              error={errors.placeTitle}
              fullWidth
              name="placeTitle"
              onChange={onChange}
              multiline
              value={userData.placeTitle}
            />
          </div>
          <div className={classes.contentItem}>
            <Typography>Адрес</Typography>
            <SearchPlaces
                onSelect={handleSelect}
                resetError={() => {
                  setErrorText('');
                  setErrors({...errors, address: false})
                }}
                errorText={errorText}
                requiredError={errors.address} />
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onSave}
          >
            {isEdit ? "Сохранить изменения" : "Создать место"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EnhancedModal;
