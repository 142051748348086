import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  chip: {
    backgroundColor: '#3f51b5',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 6px 6px 0px',
    padding: '6px 6px',
    borderRadius: 4,
    color: 'white',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none'
  },
  selected: {
    backgroundColor: '#f7802a'
  }
}));
