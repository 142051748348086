import React from 'react';
import moment from 'moment';

import FiltersModal from '../../components/FiltersModal';
import SearchBox from '../../components/TableSearch';
import AddButton from '../../components/AddButton';
import Table from '../../components/Table';
import BadgeSelector from '../../components/BadgeSelector';
// import EditNewsModal from '../../components/EditNewsModal';
import { debounce, NEWS_FILTERS } from '../../utils';
import {createCategory, getNewsList, getNewsTags, updateCategory} from '../../actions';
import CreateCategory from "../../components/CreateCategory";

function parseData(data) {
  return ({
    headers: [
      { dataAccessor: 'picture', title: '', sortable: false },
      { dataAccessor: 'title', title: 'Заголовок', sortable: true },
      { dataAccessor: 'tag', title: 'Категория', sortable: true },
      { dataAccessor: 'activeTo', title: 'Активна до', sortable: true },
      { dataAccessor: 'activeFrom', title: 'Активна с', sortable: true },
    ],
    body: data.map(x => ({
      id: x.id,
      picture: x.image,
      title: x.title,
      tag: x.tag_name || '-',
      activeTo: moment(x.active_date_to).format('DD MMMM YYYY HH:MM'),
      activeFrom: moment(x.active_date_from).format('DD MMMM YYYY HH:MM')
    })),
  });
}
//
// function uniqBy(array, name) {
//   const sortedArray = array.sort((a, b) => a[name] > b[name]);
//   return sortedArray.filter((x, i, arr) => i === arr.length -1 ? true : x[name] !== arr[i + 1][name]);
// }


export const onGetCategories = async () => {
  const { success, results } = await getNewsTags();
  if (success) {
    return results
  }
}


export default function ({ history }) {
  const [filtersIsOpen, setFiltersIsOpen] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState({});
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));
  const [categories, setCategories] = React.useState([]);

  // const [createNewsData, setCreateNewsData] = React.useState({ isOpen: false, data: {} });
  const onGetNewsList = async ({ page, search, categories, ordering, ...filters }) => {
    setFiltersData({ page, search, ...filters });
    const { success, results, count } = await getNewsList({
      page,
      search,
      filters,
      categories,
      ordering
    });
    if (success) {
      setTableData(parseData(results))
      setPageData({ page, count });
    }
  }


  React.useEffect(() => {
    onGetNewsList({ page: 1 });
    onGetCategories().then((results) => {
      setCategories(results.map(({ id, name }) => ({ id, type: name, name })))
    })
  }, []);

  const onClearFilters = (values) => {
    onGetNewsList({ page: 1 });
  };
  const onChangePage = (e, newPage) => {
    onGetNewsList({ ...filtersData, page: newPage + 1 });
  }
  const onCloseFilters = () => setFiltersIsOpen(false);
  const onClickFilters = () => setFiltersIsOpen(true);
  const debouncedNewsGet = debounce(500, onGetNewsList);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedNewsGet({ page: 1, ...filtersData, search: value })
  }

  const onSave = (values) => {
    const vals = {
      page: 1,
      ...values,
    }
    debouncedNewsGet(vals);
    setFiltersIsOpen(false);
  };

  const onSelectChanged = (values) => {
    debouncedNewsGet({ page: 1, ...filtersData, categories: values })
  };
  const onClickAdd = (values) => {
    history.push('news/add')
  };

  const onAddCategory = async (name) => {
    const body = {
      name
    };

    createCategory(body, 'news').then(() => {
      onGetCategories().then((results) => {
        setCategories(results.map(({ id, name }) => ({ id, type: name, name })))
      })
    })
  }

  const handleUpdateCategory = async (name, id) => {
    updateCategory(name, id, 'news').then(() => {
      onGetCategories().then((results) => {
        setCategories(results.map(({ id, name }) => ({ id, type: name, name })))
      })
    })
  }

  const handleClickRow = (values) => {
    history.push(`news/${values.id}`)
  };


  function handleSort(name) {
    onGetNewsList({ page: 1, ordering: name });
  }

  return (
    <React.Fragment>
      <SearchBox
        resultCount={tableData.body.length}
        onChangeSearch={onChangeSearch}
        onClickFilters={onClickFilters}
        onClearFilters={onClearFilters}
        // onClickAdd={onClickAdd}
        // filtersData={filtersData}
        // haveFilters={true}
      />
      <CreateCategory onAddCategory={onAddCategory} />
      <BadgeSelector data={categories} onSelectChanged={onSelectChanged} onUpdateCategory={handleUpdateCategory} />
      <Table
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
        onSort={handleSort}
      />
      <FiltersModal
       isOpen={filtersIsOpen}
       onSave={onSave}
       onClose={onCloseFilters}
       fields={NEWS_FILTERS}
       fieldsData={filtersData}
      />
      <AddButton onClick={onClickAdd} />

    </React.Fragment>
  );
};
