import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';

import { usePaginationStyles } from './styles';


function TablePaginationActions(props) {
  const classes = usePaginationStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <div className={classes.pages}>
        {page ? <div className={classes.page} onClick={handleBackButtonClick}>{page}</div> : null}
        <div className={classes.activePage}>{page + 1}</div>
        {page >= Math.ceil(count / rowsPerPage) - 1 ? null : <div className={classes.page} onClick={handleNextButtonClick}>{page + 2}</div>}
      </div>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

export default function Pagination(props) {
  const classes = usePaginationStyles();
  return (
    <TablePagination
      component="div"
      classes={{ root: classes.pagination, toolbar: classes.toolbar }}
      labelDisplayedRows={() => { }}
      colSpan={3}
      ActionsComponent={TablePaginationActions}
      {...props}
    />
  )
}