import Login from '../pages/Login';
import Register from '../pages/Register';
import NotFound from '../pages/404';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import User from '../pages/User';
import Chats from '../pages/Chats';
import Addresses from '../pages/Addresses';
import Trade from '../pages/Trade';
import News from '../pages/News';
import FAQ from '../pages/FAQ';
import Partners from '../pages/Partners';
import NewsDetail from '../pages/NewsDetail';
import FAQDetail from '../pages/FAQDetail';
import PartnersDetail from '../pages/PartnersDetail';
import TradeDetail from '../pages/TradeDetail';
import Chat from '../pages/Chat';
import ChatEdit from '../pages/ChatEdit';
import ChatCreate from '../pages/ChatCreate';
import AddNews from "../pages/AddNews";
import NewPartner from '../pages/NewPartner';
import NewEmployee from '../pages/NewEmployee';


export default [
  {
    path: '/login',
    component: Login,
    auth: false,
    routes: [],
  },
  {
    path: '/register',
    component: Register,
    auth: false, // Ensure this page is accessible without authentication
    routes: [],
  },
  {
    path: '/dashboard',
    component: Dashboard,
    auth: true,
    routes: [
      {
        path: '/dashboard/new-partner',
        name: 'Подписать нового партнера',
        component: NewPartner,
        exact: true,
        allowedRoles: ['admin'],
      },
      {
        path: '/dashboard/new-employee',
        name: 'Подписать нового сотрудника',
        component: NewEmployee,
        exact: true,
        allowedRoles: ['manager'],
      },
      {
        path: '/dashboard/users',
        name: 'Пользователи',
        component: Users,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/users/:id',
        name: 'Пользователи',
        component: User,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/chats',
        name: 'Чаты',
        component: Chats,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/chats/create',
        name: 'Создание чата',
        component: ChatCreate,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/chats/:id',
        name: 'Чат',
        component: Chat,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/chats/edit/:id',
        name: 'Редактирование чата',
        component: ChatEdit,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/addresses',
        name: 'Адреса',
        component: Addresses,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/trade',
        name: 'Торговля',
        component: Trade,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/trade/:id',
        name: 'Товар',
        component: TradeDetail,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/news',
        name: 'Новости',
        component: News,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/news/add',
        name: 'Новость',
        component: AddNews,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/news/edit/:id',
        name: 'Новость',
        component: AddNews,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/news/:id',
        name: 'Новость',
        component: NewsDetail,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/faq',
        name: 'FAQ',
        component: FAQ,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/faq/:id',
        name: 'FAQ',
        component: FAQDetail,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/partners',
        name: 'Партнеры',
        component: Partners,
        exact: true,
        // allowedRoles: ['admin', 'manager'],
      },
      {
        path: '/dashboard/partners/:id',
        name: 'Партнер',
        component: PartnersDetail,
        // allowedRoles: ['admin', 'manager'],
      },
    ]
  },
  {
    component: NotFound,
    routes: [],
    auth: false,
  },
]
