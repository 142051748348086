export const createUsersData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      first_name: 'Ahmed',
      last_name: 'Asadov',
      patronymic: 'Ilham ogli',
      phone_number: '+996550778133',
      city: 597,
      city_name: 'Бишкек',
      picture: null,
      user_type: 'driver',
      last_login: '2020-04-03T08:31:24.418694+03:00',
      documents_status: false,
    });
  }
  return data;
};

export const createChatsData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      title: 'Moscow',
      users_count: 155,
      created_date: '2020-04-03T08:31:24.418694+03:00',
      last_message: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const createAddressesData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      place_title: 'Moscow',
      rating: 5,
      total_rating: 15,
      last_update: '2020-04-03T08:31:24.418694+03:00',
      created_date: '2020-04-03T08:31:24.418694+03:00',
      last_comment: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const createTradeData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      title: 'Audi A3 Sedan',
      price: '4 000 000 ₽',
      category: 'Автомобили',
      city: 'Moscow',
      user: 'Doctorov Doctor Doctorovich',
      createData: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const createNewsData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      title: 'Ahmed',
      active_to: '2020-04-03T08:31:24.418694+03:00',
      active_from: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const createFAQData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      title: 'Название партнера',
      created_date: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const createPartnersData = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      title: 'Название партнера',
      created_date: '2020-04-03T08:31:24.418694+03:00',
    });
  }
  return data;
};

export const generateCategories = (length) => {
  const data = [];

  for (let i = 0; i <= length; i++) {
    data.push({
      id: i,
      name: 'Название категории' + i,
      type: i + 'type'
    });
  }
  return data;
};
