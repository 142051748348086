import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    display: "flex",
    position: "relative",
    flexWrap: "no-wrap",
    justifyContent: "space-between",
  },
  chatWrapper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    flex: 1,
    height: "90vh",
    marginRight: "10vh",
    display: "flex",
    padding: 20,
    flexDirection: "column",
    position: "relative",
    paddingBottom: 90,
    justifyContent: "flex-start",
  },
  chatContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflowY: "auto",
    overflowX: "hidden",
    height: "90vh",
    "& audio": {
      borderRadius: "20px",
      width: "100%",
    },
  },
  messageContent: {
    margin: "5px 0",
    width: "100%",
    "& img": {
      width: "100%",
      borderRadius: 6,
    },
  },
  ownMessage: {
    padding: "10px 20px",
    borderRadius: 20,
    backgroundColor: "#c3ddff",
    maxWidth: "calc(100% - 20px)",
    alignSelf: "flex-end",
    width: "100%",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  errorMessage: {
    padding: "10px 20px",
    borderRadius: 20,
    backgroundColor: "#eeeded",
    maxWidth: "calc(100% - 20px)",
    marginBottom: 20,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "flex-start",
    border: "1px solid red",
  },
  blockIconWrapper: {
    position: "absolute",
    right: "-50px",
    top: 0,
    bottom: 0,
    backgroundColor: "red",
    width: 36,
    height: 36,
    borderRadius: 18,
    display: "flex",
    margin: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  blockIcon: {
    color: "white",
    margin: "auto",
  },
  voiceMessage: {
    padding: "10px 20px",
    borderRadius: 20,
    backgroundColor: "#eeeded",
    maxWidth: "calc(100% - 20px)",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  commonMessage: {
    padding: "10px 20px",
    borderRadius: 20,
    backgroundColor: "#eeeded",
    maxWidth: "calc(100% - 20px)",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  messageTitle: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  messageUser: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageUserIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
    borderRadius: 20,
    backgroundColor: "gray",
  },
  messageUserName: {
    fontSize: 14,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: "#444",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      color: "#2a7dff",
    },
  },
  messageDate: {
    fontSize: 13,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: "gray",
    margin: "auto 0",
    marginRight: 10,
  },
  chatActions: {
    display: "flex",
    justifyContent: "flex-start",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: 20,
    alignItems: "center",
  },
  chatActionsAttach: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    "& svg": {
      width: 31,
      height: 31,
      color: "#52a1fc",
    },
  },
  attachment: {
    position: "absolute",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    opacity: 0,
    zIndex: 3,
    top: 0,
  },
  loadMessages: {
    width: 200,
    cursor: "pointer",
    padding: 10,
    textAlign: "center",
    border: "1px solid gray",
    marginBottom: 20,
    alignSelf: "center",
    borderRadius: 100,
  },
  chatActionsInput: {
    flex: 1,
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& input": {
      border: "1px solid gray",
      borderRadius: "20px",
      resize: "none",
      display: "inline-block",
      verticalAlign: "middle",
      width: "100%",
      padding: 10,
      fontSize: 16,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    "& audio": {
      borderRadius: "20px",
    },
  },
  chatActionsRecord: {
    margin: "auto 0",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "center",
    "& svg": {
      width: 36,
      height: 36,
      color: "#52a1fc",
    },
  },
  chatActionsRecordActive: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    "& svg": {
      width: 36,
      height: 36,
      color: "red",
    },
  },
  sidebar: {
    backgroundColor: "#fff",
    borderRadius: 4,
    minWidth: "40vh",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  sidebarLogo: {
    width: 220,
    height: 220,
    borderRadius: 110,
    backgroundColor: "#bebebe",
    alignSelf: "center",
    marginBottom: 30,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  sidebarTitle: {
    fontSize: 20,
    fontWeight: "500",
    width: 200,
  },
  sidebarUserCount: {
    color: "gray",
    width: 200,
  },
  sidebarBlockedCount: {
    color: "gray",
    width: 200,
  },
  sidebarActions: {
    flex: 1,
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    width: 220,
    "& button": {
      fontSize: 14,
      "&:last-child": {
        color: "red",
        marginLeft: 20,
      },
    },
  },
  arrowBack: {
    position: "relative",
    cursor: "pointer",
    color: "gray",
  },
  rightContainer: {
    display: "flex",
  },
}));
