import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowBack from '@material-ui/icons/ArrowBackIos';

import EditPartnersDetail from '../../components/EditPartnersDetail';
import { getPartner, deletePartner } from '../../actions';
import useStyles from './styles';

export default ({ location, history }) => {
  const classes = useStyles();
  const [paetnersData, setPartnersData] = React.useState({});
  const [editPartnersData, seteditPartnersData] = React.useState({ isOpen: false, data: {} });

  const onGetNews = React.useCallback(async () => {
    const dataId = location.pathname.replace(/\D/g, '');
    const { success, result } = await getPartner(dataId);
    if (success) {
      setPartnersData(result)
    }
  }, [location.pathname])

  React.useEffect(() => {
    onGetNews();
  }, [onGetNews]);

  const onEdit = () => {
    seteditPartnersData({
      isOpen: true,
    });
  }

  const onClose = () => {
    seteditPartnersData({
      isOpen: false,
    });
    onGetNews();
  }

  const onDelete = async () => {
    await deletePartner(paetnersData.id)
    history.push('/dashboard/partners')
  }

  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <ArrowBack className={classes.arrowBack} onClick={() => { history.goBack() }} />
        <div>
          <div className={classes.image} style={{ backgroundImage: `url(${paetnersData.image})` }} />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Партнер</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div>
              <span>Описание</span>
              <Typography>{paetnersData.description}</Typography>
            </div>
            <div>
              <span>Ссылка</span>
              <Typography>{paetnersData.url}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.imageDescription}>
          <div className={classes.actions}>
            <ButtonBase onClick={onEdit}>Изменить</ButtonBase>
            <ButtonBase onClick={onDelete}>Удалить</ButtonBase>
          </div>
        </div>
      </div>
      <EditPartnersDetail
        isEdit={true}
        isOpen={editPartnersData.isOpen}
        data={paetnersData || {}}
        onClose={onClose}
      />
    </div>
  );
};
