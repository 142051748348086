import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    minWidth: 400,
    minHeight: 400,
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 40,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      boxShadow: 'none',
      color: '#fff',
      width: '100%',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:first-child': {
        marginRight: 27
      },
      '&:last-child': {
        marginLeft: 27,
      },
    }
  },
  closeIcon: {
    cursor: 'pointer'
  },
  rangeInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      flex: 1,
      width: '100%',
    },
    '&>div:first-child': {
      marginRight: 20
    },
    '&>div:last-child': {
      marginLeft: 20
    }
  },
  formControl: {
    userSelect: 'none',
    marginBottom: 20,
  },
}));
