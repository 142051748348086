import React from "react";
import moment from "moment";

import FiltersModal from "../../components/FiltersModal";
import SearchBox from "../../components/TableSearch";
import AddButton from "../../components/AddButton";
import Table from "../../components/Table";
import { debounce, PARTNERS_FILTERS } from "../../utils";
import { getPartnersList } from "../../actions";
import EditPartnersDetail from "../../components/EditPartnersDetail";

function parseData(data) {
  return {
    headers: [
      { dataAccessor: "picture", title: "", sortable: false },
      { dataAccessor: "name", title: "Партнер", sortable: true },
      { dataAccessor: "created_at", title: "Дата добавления", sortable: true },
    ],
    body: data.map((x) => ({
      id: x.id,
      picture: x.image,
      name: x.name,
      description: x.description,
      created_at: moment(x.created_at).format("DD MMMM YYYY HH:mm"),
    })),
  };
}

export default function ({ history }) {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState(false);
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));
  const [loading, setLoading] = React.useState(false);
  const [editModalData, setEditModalData] = React.useState({
    isOpen: false,
    data: {},
  });

  const onGetPartnersList = async (data) => {
    setLoading(true);
    const { success, results, count } = await getPartnersList(data);
    if (success) {
      setTableData(parseData(results));
      setPageData({ page: data.page, count });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    onGetPartnersList({ page: 1 });
  }, []);

  // const onSave = (values) => {
  //   setFiltersData([]);
  //   setOpenFilter(false);
  // };
  const onClickClear = (values) => {
    setFiltersData(null);
  };
  const onChangePage = (e, newPage) => {
    onGetPartnersList({ page: newPage + 1 });
  };
  const onCloseModal = () => setOpenFilter(false);
  const onClickOptions = () => setOpenFilter(true);
  const debouncedUsersGet = debounce(500, onGetPartnersList);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedUsersGet({ page: 1, search: value });
  };
  const onClickAdd = (values) => {
    setEditModalData({
      isOpen: true,
      data: values,
    });
  };
  const onCloseAdd = (values) => {
    setEditModalData({
      isOpen: false,
      data: {},
    });
    onGetPartnersList({ page: 1 });
  };
  const handleClickRow = (values) => {
    history.push(`partners/${values.id}`);
  };

  function handleSort(name) {
    onGetPartnersList({ page: 1, ordering: name });
  }

  return (
    <React.Fragment>
      <SearchBox
        onChangeInput={onChangeSearch}
        onClickOptions={onClickOptions}
        onClickClear={onClickClear}
        resultCount={filtersData ? tableData.body.length : null}
      />
      <Table
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
        loading={loading}
        onSort={handleSort}
      />
      {/* <FiltersModal
        onClose={onCloseModal}
        open={openFilter}
        filters={PARTNERS_FILTERS}
        onSave={onSave}
      /> */}
      {/*<AddButton onClick={onClickAdd} />*/}
      <EditPartnersDetail
        isEdit={false}
        isOpen={editModalData.isOpen}
        data={editModalData.data || {}}
        onClose={onCloseAdd}
      />
      <AddButton onClick={onClickAdd} />
    </React.Fragment>
  );
}
