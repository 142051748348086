import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import useStyles from './styles';


export default function AddButton({ onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.addButtonWrapper}>
      <Fab className={classes.addButtonWrapperFab} onClick={onClick}>
        <AddIcon />
      </Fab>
    </div>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired
};
