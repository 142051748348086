import React from 'react';
import {Switch, Route, Link, Redirect} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Exit from '@material-ui/icons/ExitToApp';
import cs from 'classnames';
import logo from "../../assets/logo.svg"
import logOut from "../../assets/images/icons/logOut.svg"
import arrowRight from "../../assets/images/icons/arrowRight.svg"
import taxiPart from "../../assets/taxiPart.png"

import useStyles from './styles';

const Dashboard = ({routes, location, history}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [active, setActive] = React.useState('')
    const userType = localStorage.getItem('type');
    React.useEffect(() => {
        routes.forEach(route => {
            if (location.pathname.includes(route.path)) {
                setActive(route.name)
            }
        })
    }, [location, routes])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        localStorage.removeItem('id');
        history.push('/login');
    };

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <div className={cs(classes.drawer, open && classes.drawerOpen)}>
                    <Toolbar variant="dense" style={{minHeight:'110px',justifyContent:'space-between'}}>
                        {/* <Typography variant="h6" className={classes.title}> */}
                            {/* Шашка */}
                            <div style={{display:'flex'}}>
                                <img src={logo} alt="logo" />
                            </div>
                        {/* </Typography> */}
                        <img src={arrowRight} className={classes.menuCloseButton} onClick={handleClose}/>
                    </Toolbar>
                    <List className={classes.listContainer}>
                    {userType === "admin" && (
                        <Link to="/dashboard/new-partner">
                            <ListItem selected={'Подписать нового партнера' === active} className={classes.listItem}>
                                Новый партнер
                            </ListItem>
                        </Link>
)}
                        {userType === "manager" && (
                        <Link to="/dashboard/new-employee">
                            <ListItem selected={'Подписать нового сотрудника' === active} className={classes.listItem}>
                                Новый Сотрудник
                            </ListItem>
                        </Link>
    )}
                        <Link to="/dashboard/users">
                            <ListItem selected={'Пользователи' === active} className={classes.listItem}>
                                Пользователи
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/chats">
                            <ListItem selected={'Чаты' === active} className={classes.listItem}>
                                Чаты
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/addresses">
                            <ListItem selected={'Адреса' === active} className={classes.listItem}>
                                Адреса
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/trade">
                            <ListItem selected={'Торговля' === active} className={classes.listItem}>
                                Торговля
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/news">
                            <ListItem selected={'Новости' === active} className={classes.listItem}>
                                Новости
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/faq">
                            <ListItem selected={'FAQ' === active} className={classes.listItem}>
                                FAQ
                            </ListItem>
                        </Link>
                        <Link to="/dashboard/partners">
                            <ListItem selected={'Партнеры' === active} className={classes.listItem}>
                                Партнеры
                            </ListItem>
                        </Link>
                    </List>
                    <img src={taxiPart} alt="taxi" style={{position:'absolute',bottom:0,}}/>
                </div>
                <div className={classes.container}>
                    <AppBar position="static">
                        <Toolbar variant="dense" className={classes.toolBar}>
                            {!open && <MenuIcon onClick={handleOpen} className={classes.menuButton}/>}
                            <Typography variant="h6" className={classes.title}>
                                {active}
                            </Typography>
                            <img src={logOut} onClick={handleLogout} className={classes.exit}/>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                    <Switch>
                            {/* <Redirect from="/dashboard" to="/dashboard/users" exact /> */}
                            <Redirect from="/dashboard" exact to={
                                userType === 'manager' ? "/dashboard/new-employee" :
                                userType === 'admin' ? "/dashboard/new-partner" :
                                "/dashboard/users"
                            } />
                            {routes.map(({ name, path, component: Component, exact, allowedRoles }, i) => {
                                if (allowedRoles && !allowedRoles.includes(userType)) {
                                    return <Redirect  key={name + i}
                                    path={path}
                                    exact={exact}
                                    component={Component} to="/dashboard/users" />;
                                }
                                return (
                                    <Route
                                        key={name + i}
                                        path={path}
                                        exact={exact}
                                        component={Component}
                                    />
                                );
                            })}
                            <Redirect to="/dashboard/users" /> 
                        </Switch>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
