import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Modal from "@material-ui/core/Modal";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Block from "@material-ui/icons/Block";
import Add from "@material-ui/icons/AddOutlined";
import TextField from "@material-ui/core/TextField";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/DeleteForever";
import Photo from "@material-ui/icons/PhotoCameraOutlined";

import SearchBox from "../../components/TableSearch";
import { debounce } from "../../utils";
import cs from "classnames";

import { blockUsers, getChat, getUsersList } from "../../actions";
import CustomTable from "../../components/Table";
import useStyles from "./styles";
import moment from "moment";

export default ({ history, location }) => {
  const [title, setTitle] = React.useState("");
  const [isUsersOpen, setIsUsersOpen] = React.useState(false);
  const [isBlockedOpen, setIsBlockedOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [chatData, setChatData] = React.useState({});
  const [userAddOpen, setUserAddOpen] = React.useState(false);
  const [blockedSelectedUsers, setBlockedSelectedUsers] = React.useState([]);
  const classes = useStyles();

  const onCancel = () => {};

  const onSave = async () => {
    const chatId = location.pathname.replace(/\D/g, "");
    console.log("islog: chatData.blocked_participants.length="+chatData.blocked_participants.length);
    console.log("islog: chatData.active_participants.length="+chatData.active_participants.length);
    if (chatData.blocked_participants.length) {
      await blockUsers(
        chatData.blocked_participants.map((user) => user.id),
        true,
        chatId
      );
    } else {
      await blockUsers([], false, chatId);
    }
  };

  const onGetChat = async () => {
    const chatId = location.pathname.replace(/\D/g, "");
    const { success, result } = await getChat(chatId);
    if (success) {
      setChatData({
        ...result,
        active_participants: result.active_participants || [],
        blocked_participants: result.blocked_participants || [],
      });
    }
  };

  React.useEffect(() => {
    onGetChat();
  }, []);

  const onClickRow = (values) => () => {
    if (values.id !== +localStorage.getItem("id")) {
      setSelectedUsers((val) =>
        val.includes(values.id) > 0
          ? val.filter((x) => x !== values.id)
          : [...val, values.id]
      );
    }
  };
  const onClickBlockedRow = (values) => () => {
    setBlockedSelectedUsers((val) =>
      val.includes(values.id) > 0
        ? val.filter((x) => x !== values.id)
        : [...val, values.id]
    );
  };
  const onChange = ({ target: { value } }) => {
    setTitle(value);
  };
  const onClickArrow = () => {
    setIsUsersOpen((val) => !val);
  };
  const onClickBlockedArrow = () => {
    setIsBlockedOpen((val) => !val);
  };

  const onClickAddUsers = () => {
    setUserAddOpen(true);
  };

  const onCancelUsersAdd = () => {
    setUserAddOpen(false);
  };

  const onDeleteUsers = () => {
    setChatData({
      ...chatData,
      active_participants: chatData.active_participants.filter(
        (x) => !selectedUsers.includes(x.id)
      ),
    });
    setSelectedUsers([]);
  };

  const onBlockUsers = () => {
    setChatData({
      ...chatData,
      active_participants: chatData.active_participants.filter(
        (x) => !selectedUsers.includes(x.id)
      ),
      blocked_participants: [
        ...chatData.blocked_participants,
        ...chatData.active_participants.filter((x) =>
          selectedUsers.includes(x.id)
        ),
      ],
    });
    setSelectedUsers([]);
  };

  const onUnblockUsers = () => {
    setChatData({
      ...chatData,
      active_participants: [
        ...chatData.active_participants,
        ...chatData.blocked_participants.filter((x) =>
          blockedSelectedUsers.includes(x.id)
        ),
      ],
      blocked_participants: chatData.blocked_participants.filter(
        (x) => !blockedSelectedUsers.includes(x.id)
      ),
    });
    setBlockedSelectedUsers([]);
  };

  const onSaveUsersAdd = (values) => {
    setChatData((val) => {
      const active_participants = [...val.active_participants, ...values]
        .sort((a, b) => a.id > b.id)
        .filter((x, i, arr) =>
          i === arr.length - 1 ? true : x.id !== arr[i + 1].id
        );

      return {
        ...val,
        active_participants,
      };
    });
    setUserAddOpen(false);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderWrapper}>
          <ArrowBack
            className={classes.arrowBack}
            onClick={() => {
              history.goBack();
            }}
          />
          <div className={classes.logoWrapper}>
            <div
              className={classes.logo}
              style={{ backgroundImage: `url(${chatData.image})` }}
            />
            <div className={classes.photoWrapper}>
              <Photo className={classes.photo} />
            </div>
          </div>
        </div>
        <div className={classes.chatInfo}>
          <div className={classes.chatTitle}>
            <Typography>Редактирование чата</Typography>
          </div>
          <Divider />
          <div className={classes.chatField}>
            <Typography>Заголовок</Typography>
            <TextField
              fullWidth
              name="title"
              onChange={onChange}
              value={title || chatData.name}
            />
          </div>
        </div>
      </div>
      <div className={classes.paperContent}>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div className={classes.tableTitle}>
              <Typography>Пользователи</Typography>
              <ArrowDown
                onClick={onClickArrow}
                className={cs(
                  classes.arrowUp,
                  isUsersOpen && classes.arrowDown
                )}
              />
            </div>
            <div className={classes.tableActions}>
              {selectedUsers.length ? (
                <>
                  <Delete onClick={onDeleteUsers} />
                  <Block onClick={onBlockUsers} />
                </>
              ) : null}

              <Add onClick={onClickAddUsers} />
            </div>
          </div>
          <Divider />
          <div
            className={cs(
              classes.tableWrapper,
              isUsersOpen && classes.tableWrapperIsOpen
            )}
          >
            <Table>
              <TableBody>
                {chatData?.active_participants?.map((x, i) => (
                  <TableRow key={x.id} onClick={onClickRow(x)}>
                    <TableCell key={i} className={classes.pictureCell}>
                      <Avatar className={classes.avatar}>
                        <img src={x.image} alt={x.title} />
                        {selectedUsers.includes(x.id) ? (
                          <div className={classes.done}>
                            <Done />
                          </div>
                        ) : null}
                      </Avatar>
                    </TableCell>
                    <TableCell>{`${x.last_name} ${x.first_name} ${x.patronymic}`}</TableCell>
                    <TableCell>{x.status}</TableCell>
                    <TableCell align="right">
                      {moment(x.last_login).format("DD MMMM YYYY hh:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {chatData?.blocked_participants?.length ? (
          <div className={classes.table}>
            <div className={classes.tableHeader}>
              <div className={classes.tableTitle}>
                <Typography>Заблокированные пользователи</Typography>
                <ArrowDown
                  onClick={onClickBlockedArrow}
                  className={cs(
                    classes.arrowUp,
                    isBlockedOpen && classes.arrowDown
                  )}
                />
              </div>
              <div className={classes.tableActions}>
                {blockedSelectedUsers.length ? (
                  <Close onClick={onUnblockUsers} />
                ) : null}
              </div>
            </div>
            <Divider />
            <div
              className={cs(
                classes.tableWrapper,
                isBlockedOpen && classes.tableWrapperIsOpen
              )}
            >
              <Table>
                <TableBody>
                  {chatData?.blocked_participants?.map((x, i) => (
                    <TableRow key={x.id} onClick={onClickBlockedRow(x)}>
                      <TableCell key={i} className={classes.pictureCell}>
                        <Avatar className={classes.avatar}>
                          <img src={x.image} alt={x.title} />
                          {blockedSelectedUsers.includes(x.id) ? (
                            <div className={classes.done}>
                              <Done />
                            </div>
                          ) : null}
                        </Avatar>
                      </TableCell>
                      <TableCell>{`${x.last_name} ${x.first_name} ${x.patronymic}`}</TableCell>
                      <TableCell>{x.status}</TableCell>
                      <TableCell align="right">
                        {moment(x.last_login).format("DD MMMM YYYY hh:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : null}

        <div className={classes.actions}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onCancel}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onSave}
          >
            Сохранить
          </Button>
        </div>
        <UsersAdddModal
          isOpen={userAddOpen}
          onCancel={onCancelUsersAdd}
          onSave={onSaveUsersAdd}
          classes={classes}
        />
      </div>
    </div>
  );
};

function parseData(data) {
  return {
    headers: [
      { dataAccessor: "picture", title: "", sortable: false },
      { dataAccessor: "fullName", title: "ФИО", sortable: false },
      { dataAccessor: "phone_number", title: "Телефон", sortable: false },
      { dataAccessor: "city_name", title: "Город", sortable: false },
      { dataAccessor: "status", title: "Статус", sortable: false },
      {
        dataAccessor: "last_login",
        title: "Последнее обновление",
        sortable: false,
      },
    ],
    body: data.map((x) => ({
      id: x.id,
      picture: x.picture,
      fullName: `${x.first_name || "-"} ${x.last_name || "-"} ${
        x.patronymic || "-"
      }`,
      first_name: x.first_name,
      last_name: x.last_name,
      patronymic: x.patronymic,
      phone_number: x.phone_number || "-",
      city_name: x.city_name || "-",
      documents_status: x.documents_status,
      status: x.driver_status,
      last_login: x.last_login
        ? moment(x.last_login).format("DD MMMM YYYY HH:MM")
        : "-",
    })),
  };
}

const UsersAdddModal = ({ isOpen, onCancel, classes, onSave }) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState(false);
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const onGetUsersList = async (data) => {
    const { success, results, count } = await getUsersList(data);
    if (success) {
      setTableData(parseData(results));
      setPageData({ page: data.page, count });
    }
  };

  React.useEffect(() => {
    onGetUsersList({ page: 1 });
  }, []);

  // const onSaveFilters = (values) => {
  //   setFiltersData([]);
  //   setOpenFilter(false);
  // };
  const onClickClear = (values) => {
    setFiltersData(null);
  };
  const onClickSave = () => {
    const usersToSave = [];
    tableData.body.forEach((x) => {
      if (selectedUsers.includes(x.id)) {
        usersToSave.push(x);
      }
      return false;
    });
    onSave(usersToSave);
  };
  const onChangePage = (e, newPage) => {
    onGetUsersList({ page: newPage + 1 });
  };
  // const onCloseModal = () => setOpenFilter(false);
  const onClickOptions = () => setOpenFilter(true);
  const debouncedUsersGet = debounce(500, onGetUsersList);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedUsersGet({ page: 1, search: value });
  };

  const handleClickRow = (values) => {
    setSelectedUsers((val) =>
      val.includes(values.id) > 0
        ? val.filter((x) => x !== values.id)
        : [...val, values.id]
    );
  };

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <div className={classes.modalWrapper}>
        <SearchBox
          onChangeInput={onChangeSearch}
          onClickOptions={onClickOptions}
          onClickClear={onClickClear}
          resultCount={filtersData ? tableData.body.length : null}
        />
        <CustomTable
          data={tableData}
          handleClickRow={handleClickRow}
          onChangePage={onChangePage}
          pageData={pageData}
          selectedUsers={selectedUsers}
        />
        {/* <FiltersModal
          onClose={onCloseModal}
          open={openFilter}
          filters={USER_FILTERS}
          onSave={onSaveFilters}
        /> */}
        <div className={classes.modalActions}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onCancel}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onClickSave}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
