import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";

import { updateFAQ, createFAQ } from "../../actions";
import useStyles from "./styles";

function EnhancedModal({ isOpen, onClose, data, isEdit, categories }) {
  const [userData, setUserData] = React.useState({
    tag: data.tag || "",
    title: data.title || "",
    text: data.description || "",
  });

  const [errors, setErrors] = useState({
    tag: false,
    title: false,
    text: false,
  });

  const classes = useStyles();

  React.useEffect(() => {
    setUserData({
      tag: data.tag || "",
      title: data.title || "",
      text: data.description || "",
    });
  }, [data]);

  const onSave = async () => {
    if (
      !Object.keys(userData)
        .map((v) => {
          if (!String(userData[v].length)) {
            setErrors((errors) => {
              return { ...errors, [v]: true };
            });
          }
          return !!String(userData[v].length);
        })
        .includes(false)
    ) {
      if (isEdit) {
        const { success } = await updateFAQ({
          values: {
            tag: userData.tag,
            title: userData.title,
            description: userData.text,
          },
          id: data.id,
        });
        if (success) {
          onClose();
        }
      } else {
        const { success } = await createFAQ({
          tag: userData.tag,
          title: userData.title,
          description: userData.text,
        });
        if (success) {
          onClose();
        }
      }
    }
  };

  const onChange = ({ target: { value, name } }) => {
    setErrors({ ...errors, [name]: false });
    setUserData({ ...userData, [name]: value });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>FAQ данные</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItem}>
            <FormControl
              className={classes.formControl}
              error={errors.tag}
            >
              <Typography>
                <Box color={errors.tag ? "error.main" : "text.secondary"}>
                  Категория
                </Box>
              </Typography>
              <Select
                fullWidth
                name="tag"
                onChange={onChange}
                value={userData.tag}
              >
                {categories.map(({ name, id }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <Typography>
                <Box color={errors.title ? "error.main" : "text.secondary"}>
                  Заголовок
                </Box>
              </Typography>
              <TextField
                fullWidth
                error={errors.title}
                name="title"
                multiline
                onChange={onChange}
                value={userData.title}
              />
            </div>
            <div>
              <Typography>
                <Box color={errors.text ? "error.main" : "text.secondary"}>
                  Текст
                </Box>
              </Typography>
              <TextField
                error={errors.text}
                fullWidth
                name="text"
                multiline
                onChange={onChange}
                value={userData.text}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onSave}
          >
            {isEdit ? "Сохранить изменения" : "Создать FAQ"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EnhancedModal;
