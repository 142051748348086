import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import useStyles from './styles';

const EnhancedInput = props => <TextField {...props} />;
const EnhancedRangeInput = ({ label, className, ...rest }) => (
  <React.Fragment>
    <Typography>{label}</Typography>
    <div className={className}>
      <TextField {...rest} fullWidth label="" />
      <p>по</p>
      <TextField {...rest} value={rest.valueSecond} onChange={rest.onChangeSecond} fullWidth label="" />
    </div>
  </React.Fragment>
);
const EnhancedSelect = ({ label, cities, ...rest }) => (
  <React.Fragment>
    <Typography>{label}</Typography>
    <Select {...rest} value={rest.value}>
      {cities.map(x => (
        <MenuItem value={x.city_name}>{x.city_name}</MenuItem>
      ))}
    </Select>
  </React.Fragment>
);

export default function Inputs({ mode, cities, inputProps }) {
  const classes = useStyles();
  switch (mode) {
    case 'select':
      return <EnhancedSelect cities={cities} {...inputProps} />;
    case 'range':
      return <EnhancedRangeInput {...inputProps} className={classes.rangeInputWrapper} />;
    default:
      return <EnhancedInput {...inputProps} />;
  };
};
