import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawer: {
    width: 0,
    transition: 'width 130ms ease-in',
    background: '#FFFFFF',
    color: 'white',
    padding: 0,
    willChange: 'width',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
  },
  drawerOpen: {
    width: '300px',
    transition: 'width 130ms ease-out',
    boxShadow: '1px 1px 5px 0px #0000000D',
    zIndex:'999',
    position:'relative'
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flex: '1',
  },
  content: {
    width: '100%',
    background: '#f8f8f8',
    // height:'calc(100vh - 110px)',
    // overflow:'auto',
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize:'16px',

  },
  toolBar:{
    background:'#FFFFFF',
    color:'#484745',
    minHeight:'110px',
    padding:'0 40px 0 33px' ,
    boxShadow: '1px 1px 10px 0px #0000001A',
  },
  listContainer:{
    padding:0,
    display:'flex',
    flexDirection:'column',
    gap:'13px',
  },
  listItem: {
    color: '#CCCCCC',
    padding:'13px 30px',
    lineHeight:'14px',
    // paddingLeft: theme.spacing(3),
    cursor: 'pointer',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize:'12px',
    '&.Mui-selected, &.Mui-selected:hover': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      backgroundColor: '#FFB500',
      color: '#FFFFFF', 
    }
  },
  active: {
    color: 'white'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  menuCloseButton: {
    color:'#484745',
    cursor: 'pointer',
  },
  exit: {
    cursor: 'pointer'
  }
}));
