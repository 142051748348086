import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import useStyles from './styles';
import Settings from "@material-ui/icons/Settings";
import CategoryCreateModal from "../CategoryCreateModal";

export default function BadgeSelector({ data, onSelectChanged, onUpdateCategory }) {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const [editedItem, setEditedItem] = useState(null)

  const onSelect = ({ type }) => () => {
    const newSelected = selected.includes(type) ? [] : [type]
    setSelected(newSelected)
    onSelectChanged(newSelected)
  }

  if(!data.length) {
    return  null;
  }

  return (
    <>
      <div className={classes.wrapper}>
        {data.map(item =>
          <span
            key={item.id}
            onClick={onSelect(item)}
            className={cs(
              classes.chip,
              selected.includes(item.type) && classes.selected
            )}
          >
            {item.name}
            <Settings
              onClick={(e) => {
                e.stopPropagation();

                setEditedItem(item)
              }}
              style={{
                width: 18,
                height: 18,
                marginLeft: 4,
              }}
            />
          </span>
        )}
      </div>
      {editedItem && <CategoryCreateModal
          isOpen={!!editedItem}
          onClose={() => setEditedItem(null)}
          editedItem={editedItem}
          onSave={onUpdateCategory}
      />}
    </>
  );
};

BadgeSelector.propTypes = {
  data: PropTypes.array.isRequired,
  onSelectChanged: PropTypes.func.isRequired
}
