import React, {useEffect, useState} from 'react';
import loadScript from "../../utils/loadScripts";
import PlacesAutocomplete, {  } from 'react-places-autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";

function SearchPlaces({onSelect, errorText, resetError, requiredError}) {
    const [loaded, setLoaded] = useState(false);
    const  [value, setValue] = useState('')
    useEffect(() => {
        loadScript('googleMap', () => {
            setLoaded(true);
        });
    }, []);

    function getOptions(suggestions) {
        const options = [];
        suggestions.map(value => {
            options.push({ value: value.description, ...value });
        });

        return options;
    }

    return loaded ? (
        <PlacesAutocomplete value={value} onChange={setValue}>
            {({ getInputProps, suggestions }) => {
                return (
                    <Autocomplete
                        id="combo-box-demo"
                        options={getOptions(suggestions)}
                        getOptionLabel={(option) => option.value}
                        onInputChange={resetError}
                        onChange={(e, address) => {
                            onSelect(e.target.innerHTML || address?.value || '')
                        }}
                        renderInput={(params) =>
                            <TextField
                                error={!!requiredError || !!errorText}
                                {...params}
                                helperText={requiredError ? '' : errorText}
                                label="Search places"
                                variant="outlined"
                                {...getInputProps({})}
                            />
                        }
                    />

                )
            }}
        </PlacesAutocomplete>
    ) : null;
}

export default SearchPlaces;
