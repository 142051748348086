import React from "react";
import useStyles from "./styles";
import Button from '@material-ui/core/Button';
import CategoryCreateModal from "../CategoryCreateModal";
import Divider from "@material-ui/core/Divider";

function CreateCategory({onAddCategory, title}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(true)
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div style={{width: "100%", fontSize: 17}}>
                    {title || 'Категории'}
                </div>
                <Button onClick={handleClick} className={classes.button} variant="contained" color="primary">
                    +
                </Button>
            </div>
            <Divider style={{marginTop: 10, marginBottom: 20}} />


            <CategoryCreateModal
                isOpen={open}
                onClose={() => { setOpen(false) }}
                onSave={onAddCategory}
            />
        </>
    );
}

export default CreateCategory;
