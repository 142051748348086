import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import OptionIcon from "@material-ui/icons/Tune";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";

import useStyles from "./styles";
import { Button } from "@material-ui/core";

export default function SearchBox({
  onChangeSearch,
  onClickFilters,
  onClearFilters,
  resultCount,
  filtersData = {},
  haveFilters,
}) {
  const classes = useStyles();
  const badges = [];
  const inputRef = React.createRef();

  for (const filter in filtersData) {
    if (filter !== "page" && filter !== "search") {
      badges.push(filtersData[filter]);
    }
  }
  return (
    <>
      <div className={classes.tableSearchBox}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            ref={inputRef}
            placeholder="Поиск…"
            onChange={onChangeSearch}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
          <div className={classes.optionsIcon}>
            {haveFilters ? <OptionIcon onClick={onClickFilters} /> : null}
          </div>
        </div>
        <div className={classes.tableSearchBoxAdditional}>
          {Object.keys(filtersData).length ? (
            <IconButton
              onClick={() => {
                onClearFilters();
                inputRef.current.getElementsByTagName("input")[0].value = "";
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <Typography>{resultCount} результатов найдено</Typography>
        </div>
      </div>
      <div className={classes.wrapper}>
        {badges.map((item) => (
          <span key={item} className={classes.chip}>
            {item}
          </span>
        ))}
      </div>
    </>
  );
}
