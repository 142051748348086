import React from "react";
import useStyles from "./styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

function CategoryCreateModal({ isOpen, onClose, onSave, editedItem, ...props }) {
  const classes = useStyles();
  const [categoryName, setCategoryName] = React.useState(editedItem?.name || '');

  const changeCategoryName = ({ target: { value } }) => {
    setCategoryName(value);
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <Typography className={classes.header}>Категории</Typography>
        <Divider />

        <Typography className={classes.title}>Название</Typography>
        <Input
            className={classes.changeNameInput}
            label="Введите название"
            size="small"
            variant="outlined"
            onChange={changeCategoryName}
            value={categoryName}
        />

        <Button color="primary" size="large" variant="contained" onClick={() => {
          onSave(categoryName, editedItem?.id);
          onClose();
        }}>Сохранить</Button>
      </div>
    </Modal>
  )
}

export default CategoryCreateModal;
