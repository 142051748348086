import React from 'react';
import moment from 'moment';

import { debounce, TRADE_FILTERS } from '../../utils';
import BadgeSelector from '../../components/BadgeSelector';
import FiltersModal from '../../components/FiltersModal';
import SearchBox from '../../components/TableSearch';
import AddButton from '../../components/AddButton';
import Table from '../../components/Table';
import {getTradeTags, getTradesList, getTradeCities, createCategory, updateCategory} from '../../actions';
import EditTradesModal from '../../components/EditTradesModal';
import CreateCategory from "../../components/CreateCategory";

function parseData(data = {}, categories, cities) {
  return ({
    headers: [
      { dataAccessor: 'image', title: '', sortable: false },
      { dataAccessor: 'item', title: 'Название', sortable: true },
      { dataAccessor: 'price', title: 'Цена', sortable: true },
      { dataAccessor: 'tag_name', title: 'Категория', sortable: true },
      { dataAccessor: 'city_name', title: 'Город', sortable: true },
      { dataAccessor: 'user', title: 'Пользователь', sortable: true },
      { dataAccessor: 'created_at', title: 'Создано', sortable: false },
    ],
    body: data.map(x => ({
      id: x.id,
      image: x.images[0]?.url,
      images: x.images.map(x => x.url),
      item: x.item,
      price: x.price,
      tag: x.tag_id,
      tag_name: x.tag_name,
      city: x.city_id,
      city_name: x.city_name,
      tag_id: x.tag_id,
      city_id: x.city_id,
      user: x?.owner ? `${x.owner?.first_name} ${x.owner?.last_name} ${x.owner?.patronymic}` : '- - -',
      comment: x.comment,
      created_at: moment(x.created_at).format('DD MMMM YYYY hh:mm')
    })),
  });
}

export default function ({ history }) {
  const [filtersIsOpen, setFiltersIsOpen] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState({});
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));
  const [categories, setCategories] = React.useState([]);
  const [createTradesData, setCreateTradesData] = React.useState({ isOpen: false, data: {} });
  const [cities, setCities] = React.useState([]);

  const onGetTradesList = async ({ page, search, categories, ordering, ...filters }) => {
    setFiltersData({ page, search, ...filters });

    const { success, results, count } = await getTradesList({
      page,
      search,
      filters,
      categories,
      ordering
    });
    if (success) {
      setTableData(parseData(results))
      setPageData({ page, count });
    }
  }

  const onGetCategories = async () => {
    const { success, results } = await getTradeTags();

    if (success) setCategories(results.map(({ id, name }) => ({ id, type: name, name })))
  }

  const onGetCities = async () => {
    const { success, results } = await getTradeCities();
    if (success) {
      setCities(results);
    }
  }

  React.useEffect(() => {
    onGetTradesList({ page: 1 });
    onGetCategories();
    onGetCities();
  }, []);

  const onClearFilters = () => {
    onGetTradesList({ page: 1 })
  };
  const onChangePage = (e, newPage) => {
    onGetTradesList({ ...filtersData, page: newPage + 1 });
  }
  // const onCloseFilters = () => setFiltersIsOpen(false);
  const onClickFilters = () => setFiltersIsOpen(true);
  const debouncedUsersGet = debounce(500, onGetTradesList);
  const onChangeSearch = ({ target: { value } }) => {
    debouncedUsersGet({ page: 1, ...filtersData, search: value })
  }

  const onSave = (values) => {
    const vals = {
      page: 1,
      ...values,
    }
    debouncedUsersGet(vals);
    setFiltersIsOpen(false);
  };


  const onAddCategory = async (name) => {
    const body = {
      name
    };

    createCategory(body, 'marketplaces').then(onGetCategories)
  }

  const handleUpdateCategory = async (name, id) => {
    updateCategory(name, id, 'marketplaces').then(onGetCategories)
  }

  const onSelectChanged = async (values) => {
    debouncedUsersGet({ page: 1, ...filtersData, categories: values })
  };
  const onClickAdd = (values) => {
    setCreateTradesData({
      isOpen: true,
      data: values,
    })
  };
  const onCloseAdd = () => {
    setCreateTradesData({
      isOpen: false,
      data: {},
    })
    onGetTradesList({ page: 1 });
  }
  const handleClickRow = (values) => {
    history.push(`trade/${values.id}`)
  };


  function handleSort(name) {
    onGetTradesList({ page: 1, ordering: name });
  }


  return (
    <React.Fragment>
      <SearchBox
         resultCount={tableData.body.length}
         onChangeSearch={onChangeSearch}
         onClickFilters={onClickFilters}
         onClearFilters={onClearFilters}
         // onClickAdd={onClickAdd}
         // filtersData={filtersData}
         // haveFilters={true}
      />
      <CreateCategory onAddCategory={onAddCategory} />
      <BadgeSelector data={categories} onSelectChanged={onSelectChanged} onUpdateCategory={handleUpdateCategory} />
      <Table
        onSort={handleSort}
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
      />
      {/*<FiltersModal*/}
      {/*  isOpen={filtersIsOpen}*/}
      {/*  onSave={onSave}*/}
      {/*  onClose={onCloseFilters}*/}
      {/*  fields={TRADE_FILTERS}*/}
      {/*  fieldsData={filtersData}*/}
      {/*/>*/}
      <AddButton onClick={onClickAdd} />
      <EditTradesModal
        onClose={onCloseAdd}
        isOpen={createTradesData.isOpen}
        data={createTradesData.data}
        isEdit={false}
        cities={cities}
        categories={categories}
      />
    </React.Fragment>
  );
};
