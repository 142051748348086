import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    width: '65vh',
    height: 'auto',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    transform: 'translateY(5vh)',
  },
  header: {
    '& > p': {
      fontSize: 20,
      margin: 0,
    },
  },
  title: {
    marginTop: 30,
  },
  selector: {
    marginBottom: 20,
  },
  changeNameInput: {
    marginBottom: 20,
  }
}))
