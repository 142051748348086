import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import useStyles from './styles';
import { createEmployee } from '../../actions';
import arrowButtom from "../../assets/images/icons/arrowButtom.svg"
import arrowTop from "../../assets/images/icons/arrowTop.svg"

const roles = ["Менеджер"];
const placeholders = {
    name: "Имя",
    surname:"Фамилия",
    phone_number: "Номер телефона",
    email: "E-mail",
    employer_type: "Роль",
};

const StyledTextField = styled(({ hasContent, isError, ...otherProps }) => <TextField {...otherProps} />)`
    margin-top: 26px;
    margin-bottom: 4px;
    width: 50%;
    & .MuiOutlinedInput-root {
        border-radius: 8px;
        & fieldset {
            border: 1px solid ${({ hasContent, isError }) => isError ? 'red' : hasContent ? '#FFB500' : '#CCCCCC'};
        }
        &:hover fieldset {
            border: 1px solid #FFB500 !important;
        }
        &:focus-within fieldset {
            border: 1px solid #FFB500;
        }
    }
    & .MuiFormHelperText-root {
        color: ${({ isError }) => isError ? 'red' : 'inherit'};
    }
    & input {
        padding: 15.5px 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: ${({ hasContent }) => hasContent ? '#757575' : 'CurrentColor'};
    }
    & .MuiInputLabel-outlined {
        transform: translate(22px, 15.5px) scale(1);
    }
    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(22px, -6px) scale(0.75);
    }
`;

const StyledButton = styled(({ disabled, ...otherProps }) => <Button {...otherProps} />)`
    background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
    color: ${({ disabled }) => disabled ? '#FBFBFB' : '#FFFFFF'};
    box-shadow: ${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    border-radius: 8px;
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    &:hover {
        background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
        box-shadow: ${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // margin-bottom: 16px;

    & > div {
        flex: 1;  // Makes each child div take up equal space
        &:first-child {
            margin-right: 60px;  // Adds a gap between the two fields
        }
    }
`;
const DropdownContainer = styled.div`
  position: relative;
  width: 50%;
  margin-top: 26px;
  margin-bottom: 4px;
`;

const DropdownButton = styled.div`
  padding: 14px 16px 14px 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height:19.2px;
  background-color: none;
  border: 1px solid ${({ hasValue }) => hasValue ? '#FFB500' : '#CCCCCC'};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ hasValue }) => hasValue ? '#757575' : '#949494'}; // Change color based on whether there is a value

  &:hover {
    border-color: #FFB500;
  }
`;


const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  background-color: #FBFBFB;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  margin-top: 5px;
  z-index: 1000;
  overflow:hidden;
`;

const DropdownItem = styled.div`
  padding: 14px 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor:pointer;
  color:#484745;
  background-color: ${({ isActive }) => isActive ? '#FFB500' : 'transparent'};
  &:hover {
    background-color: #FFB500;
  }
`;

const StyledSnackbar = styled(Snackbar)`
    & .MuiSnackbarContent-root {
        background-color: #FFB500;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size:16px;
    }
`;

const CustomSelect = ({ placeholder, options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
  
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);
  
    return (
      <DropdownContainer ref={containerRef}>
        <DropdownButton 
          onClick={() => setIsOpen(!isOpen)} 
          isOpen={isOpen}
          hasValue={value !== ''} // Passing whether value is set or not
        >
          {value || placeholder}
          <span style={{display:'flex',alignItems:'center',userSelect: 'none'}}>{isOpen ? <img src={arrowTop} alt="" /> : <img src={arrowButtom} alt="" />}</span>
        </DropdownButton>
        {isOpen && (
          <DropdownList>
            {options.map(option => (
              <DropdownItem key={option} onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
              isActive={option === value}
              >
                {option}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </DropdownContainer>
    );
  };
  
export default function ({ history }) {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: "",
        surname:"",
        phone_number: "",
        email: "",
        employer_type: "",

    });
    const [errors, setErrors] = useState({});
    const [displayedEmployerType, setDisplayedEmployerType] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateInput(name, value);
    };

    const validateInput = (name, value) => {
        let error = "";
        switch (name) {
            case 'email':
                if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "Invalid email address.";
                }
                break;
            case 'phone_number':
                if (/[^0-9+\-\s()]/.test(value)) {  // Checks for any character that is not a digit, plus sign, dash, space, or parentheses
                    error = "Phone number must contain only numbers and standard symbols (+, -, spaces, or parentheses).";
                }
                break;
            default:
                if (!value.trim()) {
                    error = "This field is required.";
                }
                break;
        }
        setErrors(prev => ({ ...prev, [name]: error }));
    };

    const handleSubmit = async () => {
        // Check again if there are any errors or empty fields before proceeding
        const anyErrors = Object.values(errors).some(e => e);
        const anyEmpty = Object.values(formData).some(v => !v.trim());

        if (anyErrors || anyEmpty) {
            return;
        }

        const response = await createEmployee(formData);
        if (response && response.success) {
            console.log('Employee created successfully:', response);
            history.push('/dashboard');
        } else {
            handleSnackbarOpen(response.message);
            console.error('Failed to create taxopark:', response);
        }
    };

    return (
        <React.Fragment>
            <div style={{ height: 'calc(100vh - 158px)', display: 'flex', flexDirection: 'column' }}>
            <div className={classes.textContainer}>
                <div className={classes.text}>Пожалуйста заполните все данные.</div>
                <div className={classes.subText}>Для добавления нового сотрудника заполните все поля.</div>
            </div>
                <form className={classes.form} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div>
                        {Object.keys(formData).map((key, index) => {
                            if (index % 2 === 0) { // Even index
                                const nextKey = Object.keys(formData)[index + 1]; // Get the next field
                                if (key === 'employer_type') {
                                    return (
                                    <FlexContainer key={key}>
                                        <CustomSelect
                                        placeholder={placeholders.employer_type}
                                        options={roles}
                                        value={displayedEmployerType}
                                        onChange={(value) => {
                                            const actualValue = value === "Менеджер" ? "director" : value;
                                            setFormData({ ...formData, employer_type: actualValue });
                                            setDisplayedEmployerType(value);
                                        }}
                                    />
                                       {nextKey ? (
                                            <CustomSelect
                                                placeholder={placeholders[nextKey]}
                                                options={roles}
                                                value={displayedEmployerType}
                                                onChange={(value) => {
                                                    const actualValue = value === "Менеджер" ? "director" : value;
                                                    setFormData({ ...formData, employer_type: actualValue });
                                                    setDisplayedEmployerType(value);
                                                }}
                                            />
                                        ):(
                                            <div></div>
                                        )}
                                    </FlexContainer>
                                    );
                                }
                                return (
                                    <FlexContainer key={key}>
                                        <StyledTextField
                                            type={key.includes('email') ? 'email' : 'text'}
                                            placeholder={placeholders[key]}
                                            name={key}
                                            value={formData[key]}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="normal"
                                            hasContent={Boolean(formData[key])}
                                        />
                                        {nextKey ? (
                                            <StyledTextField
                                                type={nextKey.includes('email') ? 'email' : 'text'}
                                                placeholder={placeholders[nextKey]}
                                                name={nextKey}
                                                value={formData[nextKey]}
                                                onChange={handleChange}
                                                variant="outlined"
                                                margin="normal"
                                                hasContent={Boolean(formData[nextKey])}
                                            />
                                        ):(
                                            <div></div>
                                        )}
                                    </FlexContainer>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={Object.values(errors).some(e => e) || Object.values(formData).some(v => !v.trim())}
                    >
                        Зарегистрировать
                    </StyledButton>
                </form>
            </div>
            <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ top: 120 }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
        />
        </React.Fragment>
    );
}
