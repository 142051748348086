import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import { getCities } from '../../actions'
import useStyles from './styles';
import Inputs from './Inputs';

export default function FiltersModal({
  fields = [],
  isOpen,
  onClose,
  onSave,
  fieldsData = {},
}) {
  const classes = useStyles();
  const [data, setData] = React.useState(fieldsData || {})
  const [cities, setCities] = React.useState([]);

  const onGetCities = async () => {
    const { result, success } = await getCities();
    if (success) {
      setCities(result || [])
    }
  }

  React.useEffect(() => {
    onGetCities();
  }, [])

  React.useEffect(() => {
    setData(fieldsData)
  }, [JSON.stringify(fieldsData)])

  const onClickSave = () => onSave(data);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Фильтры</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          {fields.map(filter => (
            <FormControl key={filter.title} className={classes.formControl}>
              <Inputs
                mode={filter.mode}
                cities={cities}
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setData({
                      ...data,
                      [filter.name]: value
                    });
                  },
                  label: filter.title,
                  value: data[filter.mode === 'range' ? filter.nameFirst : filter.name] || '',
                  type: filter.inputType,
                  variant: 'outlined',
                  ...(filter.mode === 'range' ? ({
                    onChange: ({ target: { value } }) => {
                      setData({
                        ...data,
                        [filter.nameFirst]: value
                      });
                    },
                    onChangeSecond:  ({ target: { value } }) => {
                      setData({ ...data, [filter.nameSecond]: value})
                    },
                    value: data[filter.nameFirst] || '',
                    valueSecond: data[filter.nameSecond] || '',
                  }) : {})
                }}
              />
            </FormControl>
          ))}
        </div>
        <div className={classes.actions}>
          <Button color="secondary" size="large" variant="contained" onClick={onClose}>Отменить</Button>
          <Button color="primary" size="large" variant="contained" onClick={onClickSave}>Сохранить</Button>
        </div>
      </div>
    </Modal>
  );
};
