import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    width: '70%',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'auto',
    backgroundColor: '#fff',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  contentItem: {
    marginLeft: 20,
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      margin: '0 0 20px 0',
    }
  },
  greyText: {
    color: 'grey'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    maxHeight: '60vh',
    marginBottom: 40,
    overflow: 'scroll',
    justifyContent: 'space-between'
  },
  images: {
    width: 300,
    height: 300,
    margin: 'auto',
    backgroundColor: '#999',
  },
  image: {
    width: 300,
    flex: 1,
    height: 300,
    backgroundSize: 'cover',
    backgroundColor: '#999',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      boxShadow: 'none',
      color: '#fff',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:last-child': {
        marginLeft: 27,
      },
    }
  },
  closeIcon: {
    cursor: 'pointer'
  },
  rangeInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      flex: 1,
      width: '100%',
    },
    '&>div:first-child': {
      marginRight: 20
    },
    '&>div:last-child': {
      marginLeft: 20
    }
  },
  formControl: {
    width: '100%'
  },
  photoWrapper: {
    position: 'absolute',
    zIndex: 2,
    top: '15%',
    left: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75%',
    height: '75%',
    cursor: 'pointer',
  },
  photo: {
    width: 50,
    height: 50,
    color: 'white',
  },
  input: {
    position: 'absolute',
    width: '75%',
    height: '75%',
    cursor: 'pointer',
    opacity: 0,
    zIndex: 3,
    top: '15%',
    left: '15%',
  },
  contentItemWithMap: {
    position: 'relative'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& p': {
      width: '100%',
    },
    '& div': {
      flex: 1,
      '&:first-child': {
        marginRight: 20,
      }
    }
  },
  price: {
    '& input': {
      paddingLeft: 20,
    },
    '&:before': {
      content: "'₽'",
      position: 'absolute',
      left: 0,
      padding: '8px 0',
      color: 'grey',
      top: 0,
      bottom: 0,
    }
  }
}));
