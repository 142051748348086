import React, {useState, useEffect} from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Photo from '@material-ui/icons/PhotoCameraOutlined';
import { updateUser, getCities } from '../../actions';

import useStyles from './styles';


function EnhancedModal({ isOpen, onClose, data }) {
  const [isError, setIsError] = useState(false);
  const [userData, setUserData] = useState({
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    patronymic: data.patronymic || '',
    city: data.city || '',
    picture: data.picture || '',
    phone_number: data?.phone_number || ''
  })
  const [cities, setCities] = useState([]);
  const classes = useStyles();

  const onGetCities = async () => {
    const { result, success } = await getCities();
    if (success) {
      setCities(result || [])
    }
  }

  useEffect(() => {
    onGetCities();
  }, [])

  useEffect(() => {
    setUserData({
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      patronymic: data.patronymic || '',
      city: data.city || '',
      picture: data.picture || '',
      phone_number: data?.phone_number || ''
    })
  }, [data]);

  const onSave = async () => {
    const formData = new FormData();
    Object.keys(userData).forEach((v) => {
      if(v !== 'picture') {
        formData.append(v, userData[v])
      }
    })
    userData.file && formData.append('picture', userData.file);
    const { success } = await updateUser(formData, data.id)
    if (success) {
      setIsError(false);
      onClose();
    } else {
      setIsError(true)
    }
  }

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setUserData({ ...userData, picture: URL.createObjectURL(target.files[0]), file: target.files[0] })
    }
  }

  const onChange = ({ target: { value, name } }) => {
    setUserData({ ...userData, [name]: value });
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Личные данные</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItemWithMap}>
            <div className={classes.image} style={{ backgroundImage: `url(${userData.picture})` }} />
            <input type="file" name="picture" className={classes.input} onChange={onChangeImage} />
            <div className={classes.photoWrapper}>
              <Photo className={classes.photo} />
            </div>
          </div>
          <div className={classes.contentItem}>
            <Typography>Фамилия</Typography>
            <TextField
              fullWidth
              name="last_name"
              onChange={onChange}
              value={userData.last_name}
            />
          </div>
          <div className={classes.contentItem}>
            <Typography>Имя</Typography>
            <TextField
              fullWidth
              name="first_name"
              onChange={onChange}
              value={userData.first_name}
            />
          </div>
          <div className={classes.contentItem}>
            <Typography>Отчество</Typography>
            <TextField
              fullWidth
              name="patronymic"
              onChange={onChange}
              value={userData.patronymic}
            />
          </div>
          <div className={classes.contentItem}>
            <Typography>Город</Typography>
            <Select fullWidth value={userData.city} name="city" onChange={onChange}>
              {cities?.map(x => (
                <MenuItem key={x.city} value={x.city_name}>{x.city_name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.contentItem}>
            <Typography>Номер Телефона</Typography>
            <TextField
                fullWidth
                name="phone_number"
                onChange={onChange}
                value={userData.phone_number}
            />
          </div>
        </div>
        <div className="content">
          <div className={classes.contentItem}>
            {isError && <Typography className={classes.errorMess} color="secondary" variant="caption">Что-то пошло не так</Typography>}
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="secondary" size="large" variant="contained" onClick={onClose}>Отменить</Button>
          <Button color="primary" size="large" variant="contained" onClick={onSave}>Сохранить изменения</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnhancedModal;
