import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Done from '@material-ui/icons/Done';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './styles';
import Pagination from './Pagination';
import cs from 'classnames';

export default function EnhancedTable({
  data: { headers, body },
  handleClickRow,
  onChangePage,
  pageData,
  selectedUsers = [],
  onSort
}) {
  const [sortTypes, setSortTypes] = React.useState({});
  const classes = useStyles();
  const onClickRow = values => () => {
    handleClickRow(values)
  }

  const onClickSort = (name) => {
    const fullName = name === 'fullName' && 'last_name';

    setSortTypes(x => {
            if(!x[name].active) {
                x[name].active = true;
                onSort(fullName || name);
            } else {
                if(x[name].sort === 'asc') {
                  const sort = fullName  ? '-' + fullName : '-' + name
                    onSort(sort);
                    x[name].sort = 'desk';
                } else {
                    onSort('');
                    x[name].active = false
                    x[name].sort = 'asc';
                }
            }

            Object.keys(sortTypes).forEach((v, i) => {
                if(v !== name) {
                    x[v].sort = 'asc';
                    x[v].active = false;
                }
            })
        return x;
    });
  }

  useEffect(() => {
    if(!Object.keys(sortTypes).length) {
      const types = {}

      headers.forEach(v => {

        if(v.sortable) {
          types[v.dataAccessor] = {
            active: false,
            sort: 'asc'
          };
        }
      });
      setSortTypes(types);
    }
  }, [headers]);

  return (
    <div className={classes.wrapper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((x, i) =>
                <TableCell
                  className={classes.tableCell}
                  key={i}
                  onClick={() => x.sortable ? onClickSort(x.dataAccessor) : () => {}}
                >
                  <div className={x.sortable ? classes.header : null}>
                    <p>{x.title}</p>
                    {x.sortable ? <ArrowDown style={{...(sortTypes[x.dataAccessor]?.active && {opacity: 1})}} className={cs(classes.sort, sortTypes[x.dataAccessor]?.sort  === 'asc'  && classes.asc)}/> : null}
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((values, index) => (
              <TableRow style={{backgroundColor: values.isNew ? '#f3d64645' : 'transparent'}} key={index} onClick={onClickRow(values)}>
                {headers.map((x, i) => (
                  (x.dataAccessor === 'picture' || x.dataAccessor === 'image') ?
                    <TableCell key={i} className={classes.pictureCell}>
                      <Avatar classes={{
                        root: classes.avatar
                      }}>
                        <img src={values[x.dataAccessor]} alt={x.title} />
                        {selectedUsers.includes(values.id) ? (
                          <div className={classes.done}>
                            <Done />
                          </div>
                        ) : null}
                      </Avatar>
                    </TableCell>
                    :
                    <TableCell key={i}>{values[x.dataAccessor]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        rowsPerPageOptions={[10]}
        count={pageData.count}
        rowsPerPage={10}
        page={pageData.page - 1}
        onChangePage={onChangePage}
      />
    </div>
  );
};

EnhancedTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.array.isRequired,
    body: PropTypes.array.isRequired,
  }),
  pageData: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired
};
