import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Settings from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import Done from "@material-ui/icons/Done";
import fallbackImage from "../../assets/fallback.jpg";

import moment from "moment";

import EditDocumentsModal, {
  EnhancedServiceModal,
} from "../../components/EditDocumentsModal";
import EditUserModal from "../../components/EditUserModal";
import EditCommentModal from "../../components/EditCommentModal";
import PaymentDetailsModal from "../../components/PaymentDetailsModal";

import {
  getUser,
  deleteUser,
  acceptDocument,
  rejectDocument,
  rejectorAcceptVehicle,
  changeUserComment,
  updateUser,
  changePaymentDetails,
} from "../../actions";
import useStyles from "./styles";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import Edit from "@material-ui/icons/Edit";

export default ({ location, history }) => {
  const classes = useStyles();
  const userId = location.pathname.replace(/\D/g, "");

  const [userData, setUserData] = React.useState({
    documents: {
      passport_both_sides: [],
      drivers_license_both_sides: [],
      sts_both_sides: [],
      other_documents: [],
    },
  });

  useEffect(() => {
    if (userData?.is_new_user) {
      setUserData({ ...userData, is_new_user: false });
      const newData = { ...userData, is_new_user: false };

      const formData = new FormData();
      Object.keys(newData).forEach((v) => {
        if (v !== "picture") {
          formData.append(v, newData[v]);
        }
      });
      newData.file && formData.append("picture", newData.file);
      updateUser(formData, userId);
    }
    console.log(userData,'xxx')
  }, [userData]);

  const [openEditModalData, setOpenEditModalData] = React.useState({
    isOpen: false,
    data: { files: [] },
  });
  const [userEditData, setUserEditData] = React.useState({
    isOpen: false,
    data: {},
  });
  const [isOpenCommentModal, setIsOpenCommentModal] = React.useState(false);
  const [comment, setComment] = React.useState(userData.comment || "");
  const [isOpenPaymentDetailsModal, setIsOpenPaymentDetailsModal] = useState(
    false
  );
  const statuses = {
    accepted: {
      title: "Подтверждено",
      className: classes.accepted,
      wrapperClassName: classes.documents,
    },
    rejected: {
      title: "Отклонен",
      className: classes.rejected,
      wrapperClassName: classes.documents,
    },
    awaiting_to_load: {
      title: "Ожидает загрузки",
      className: classes.waiting,
      wrapperClassName: classes.documents,
    },
    awaiting_confirmation: {
      title: "Ожидает подтверждения",
      className: classes.waiting,
      wrapperClassName: classes.documentsWaiting,
    },
  };

  const onGetUser = async () => {
    const userId = location.pathname.replace(/\D/g, "");
    if(userId){
      const { success, result } = await getUser(userId);
      if (success) {
        setUserData(result);
      }
    }
  };

  const onEdit = (data) => {
    setOpenEditModalData({
      isOpen: true,
      data,
    });
  };

  const onCloseEdit = (data) => {
    setOpenEditModalData({
      isOpen: false,
      data: {
        files: [],
      },
    });
  };

  const onReject = async ({ type, reason }) => {
    const { success } = await rejectDocument({
      userId: userData.id,
      type,
      reason,
    });
    if (success) {
      onGetUser();
      onCloseEdit();
    }
  };
  const onAccept = async ({ type }) => {
    const { success } = await acceptDocument({ userId: userData.id, type });
    if (success) {
      onGetUser();
      onCloseEdit();
    }
  };

  const onRejectorAcceptVehicle = async ({ vehicle_id, reason, action }) => {
    const { success } = await rejectorAcceptVehicle({
      vehicle_id,
      action,
      reason,
    });
    if (success) {
      onGetUser();
      onCloseEdit();
    }
  };

  const onCloseUserEdit = () => {
    onGetUser();
    setUserEditData({
      isOpen: false,
      data: {},
    });
  };

  const onOpenUserEdit = () => {
    setUserEditData({
      isOpen: true,
      data: userData,
    });
  };

  const onOpenUserDelete = async () => {
    await deleteUser(userData.id);
    history.push("/dashboard/users");
  };

  React.useEffect(() => {
    onGetUser();
  }, [location]);

  const onChangeUserComment = async (val) => {
    const res = await changeUserComment({
      user_id: userData.id,
      comment: val,
    });
    if (res.success) {
      setComment(res.result);
    }
  };

  const onSavePaymentDetail = async (val) => {
    const res = await changePaymentDetails(userId, val);

    if (res.success) {
      onGetUser();
    }
  };

  return (

    <div className={classes.paper}>
      <div className={classes.sticky}>
        <ArrowBack
          className={classes.arrowBack}
          onClick={() => {
            history.goBack();
          }}
        />
        <div>
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${userData.picture || fallbackImage})`,
            }}
          />
          <div className={classes.imageDescription}>
            <Typography style={{display: "flex", justifyContent: "space-between"}}>
              {userData.last_name || "Не указан"} &nbsp;
              {userData.first_name || "Не указан"} &nbsp;
              {userData.patronymic || "Не указан"} &nbsp;
              <Edit
                  className={classes.editComment}
                  onClick={onOpenUserEdit}
              />
            </Typography>
            <Typography
              className={
                userData.driver_status === "Документы проверены" &&
                classes.accepted
              }
              color="secondary"
              variant="caption"
            >
              {userData.driver_status}
            </Typography>
            <Typography variant="subtitle2" className={classes.greyText}>
              {`Последний вход в ${moment(userData.last_seen).format(
                "HH:mm DD MMMM YYYY"
              )}`}
            </Typography>
            <div className={classes.comment}>
              <Typography className={classes.commentWrapper}>
                Комметарий к водителю:
                <Edit
                  className={classes.editComment}
                  onClick={() => {
                    setIsOpenCommentModal(true);
                  }}
                />
              </Typography>
              <Divider />
              <Typography variant="caption" className={classes.greyText}>
                {comment || userData.comment}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Личные данные</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div>
              <span>Телефон</span>
              <Typography>{userData.phone_number || "Не указан"}</Typography>
            </div>
            <div>
              <span>Дата регистрации</span>
              <Typography>
                {moment(userData.registration_date).format("DD MMMM YYYY") ||
                  "Не указан"}
              </Typography>
            </div>
            <div>
              <span>Город</span>
              <Typography>{userData.city || "Не указан"}</Typography>
            </div>
          </div>
        </div>
        {/*<div className={classes.wrapperItem}>*/}
        {/*  <div className={classes.header}>*/}
        {/*    <Typography>Платежные данные</Typography>*/}
        {/*    <div className={classes.settings}>*/}
        {/*      <IconButton onClick={() => setIsOpenPaymentDetailsModal(true)}>*/}
        {/*        <Settings />*/}
        {/*      </IconButton>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <Divider />*/}
        {/*  <div className={classes.userData}>*/}
        {/*    <div>*/}
        {/*      <span>Статус</span>*/}
        {/*      <Typography*/}
        {/*        className={cs({*/}
        {/*          [statuses[userData?.payment_information?.status]*/}
        {/*            ?.className]: statuses[*/}
        {/*            userData?.payment_information?.status*/}
        {/*          ],*/}
        {/*        })}*/}
        {/*      >*/}
        {/*        {statuses[userData?.payment_information?.status]?.title ||*/}
        {/*          "Нет данных"}*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={classes.userData}>*/}
        {/*    <div*/}
        {/*      className={cs({*/}
        {/*        [classes.updatedPaymentHighlight]:*/}
        {/*          userData?.payment_information?.is_updated,*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <span>Номер счета</span>*/}
        {/*      <Typography>*/}
        {/*        {userData?.payment_information?.account_number || "Не указан"}*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={cs({*/}
        {/*        [classes.updatedPaymentHighlight]:*/}
        {/*          userData?.payment_information?.is_updated,*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <span>БИК</span>*/}
        {/*      <Typography>*/}
        {/*        {userData?.payment_information?.bik || "Не указан"}*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={classes.userData}>*/}
        {/*    <div*/}
        {/*      className={cs({*/}
        {/*        [classes.updatedPaymentHighlight]:*/}
        {/*          userData?.payment_information?.is_updated,*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <span>Номер карты</span>*/}
        {/*      <Typography>*/}
        {/*        {userData?.payment_information?.card_number || "Не указан"}*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <span>Имя на карте</span>*/}
        {/*      <Typography>*/}
        {/*        {userData?.payment_information?.full_name || "Не указано"}*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Документы</Typography>
          </div>
          <Divider />
          <Documents
            data={userData.documents.passport_both_sides}
            classes={classes}
            onEdit={onEdit}
            title="Паспорт"
            statuses={statuses}
          />
          <Documents
            data={userData.documents.drivers_license_both_sides}
            classes={classes}
            onEdit={onEdit}
            title="Водительское удостоверение"
            statuses={statuses}
          />
          <Documents
            data={userData.documents.sts_both_sides}
            classes={classes}
            onEdit={onEdit}
            title="СТС"
            statuses={statuses}
          />
          <OtherDocuments
            data={userData.documents.other_documents}
            classes={classes}
            onEdit={onEdit}
            statuses={statuses}
          />
        </div>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Автомобили</Typography>
          </div>
          <Divider />
          {userData.vehicles && userData.vehicles.length ? (
            <CarDocuments
              data={userData.vehicles || []}
              classes={classes}
              onEdit={onEdit}
              statuses={statuses}
            />
          ) : (
            <Typography className={classes.emptyState}>Не загружены</Typography>
          )}
        </div>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Сервисы</Typography>
          </div>
          <Divider />
          <Aggregators
            data={userData.aggregators || []}
            phoneNumber={userData.phone_number}
            classes={classes}
            location={location}
            onGetUser={onGetUser}
          />
        </div>
        <div className={classes.actions}>
          <ButtonBase onClick={onOpenUserEdit}>Изменить</ButtonBase>
          <ButtonBase onClick={onOpenUserDelete}>Удалить</ButtonBase>
        </div>
      </div>
      <EditDocumentsModal
        onClose={onCloseEdit}
        userId={userData.id}
        isOpen={openEditModalData.isOpen}
        data={openEditModalData.data}
        onReject={onReject}
        onAccept={onAccept}
        onRejectorAcceptVehicle={onRejectorAcceptVehicle}
      />
      <EditUserModal
        isOpen={userEditData.isOpen}
        onClose={onCloseUserEdit}
        data={userEditData.data}
      />
      <EditCommentModal
        isOpen={isOpenCommentModal}
        onClose={() => {
          setIsOpenCommentModal(false);
        }}
        onSave={onChangeUserComment}
        data={comment || userData.comment}
      />
      <PaymentDetailsModal
        onSave={onSavePaymentDetail}
        onClose={() => setIsOpenPaymentDetailsModal(false)}
        isOpen={isOpenPaymentDetailsModal}
        data={userData?.payment_information}
      />
    </div>
  );
};

function Documents({ data, classes, title, onEdit, statuses }) {
  const statusName =
    data.length === 1
      ? "awaiting_to_load"
      : data[0]?.status || "awaiting_to_load";
  const status = statuses[statusName];
  const onClickEdit = () => {
    onEdit({
      files: data.map((x) => x.file),
      documentType: data[0]?.document_type,
      title,
    });
  };
  return (
    <div className={status.wrapperClassName}>
      <div className={classes.documentsDescription}>
        <div>
          <Typography>{title}</Typography>
          {statusName !== "awaiting_to_load" ? (
            <span className={classes.greyText}>
              Загружено {moment(data[0]?.uploaded_at).format("DD MMMM YYYY")}
            </span>
          ) : (
            <span className={classes.greyText}> {status.title}</span>
          )}
        </div>
        {statusName !== "awaiting_to_load" && (
          <Typography className={status.className}>{status.title}</Typography>
        )}
      </div>
      <div className={classes.documentsFiles}>
        {data.map((x) => (
          <div key={x.id} className={classes.documentImageWrapper}>
            <div
              className={classes.documentImage}
              style={{ backgroundImage: `url(${x.file})` }}
            />
            {x.status === "accepted" ? (
              <div className={classes.done}>
                <Done />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {statusName !== "awaiting_to_load" && (
        <div className={classes.settings}>
          <IconButton onClick={onClickEdit}>
            <Settings />
          </IconButton>
        </div>
      )}
    </div>
  );
}

function OtherDocuments({ data, classes, onEdit, statuses }) {
  const titles = {
    migration_card: "Миграционная карта",
    registration: "Регистрация",
    license: "Лицензия",
    photo: "Фото водителя",
  };
  const onClickEdit = (data) => () => {
    onEdit(data);
  };

  return (
    <React.Fragment>
      {data.map((x) => (
        <div key={x.id} className={statuses[x.status].wrapperClassName}>
          <div className={classes.documentsDescription}>
            <div>
              <Typography>{titles[x.document_type]}</Typography>
              <span className={classes.greyText}>
                Загуржено {moment(data[0]?.uploaded_at).format("DD MMMM YYYY")}
              </span>
            </div>
            <Typography className={statuses[x.status].className}>
              {statuses[x.status].title}
            </Typography>
          </div>
          <div className={classes.documentsFiles}>
            <div
              className={classes.documentImage}
              style={{ backgroundImage: `url(${x.file})` }}
            />
            {x.status === "accepted" ? (
              <div className={classes.done}>
                <Done />
              </div>
            ) : null}
          </div>
          <div className={classes.settings}>
            <IconButton
              onClick={onClickEdit({
                title: titles[x.document_type],
                files: [x.file],
                documentType: x.document_type,
              })}
            >
              <Settings />
            </IconButton>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

function CarDocuments({ data, classes, onEdit, statuses }) {
  const onClickEdit = (values) => () => {
    onEdit(values);
  };
  return (
    <React.Fragment>
      {data.map((x) => (
        <div key={x.id} className={statuses[x.status].wrapperClassName}>
          <div className={classes.documentsDescription}>
            <div>
              <Typography>{x.government_number}</Typography>
              <Typography>
                <span className={classes.greyText}>{x.brand}</span>
              </Typography>
              <span className={classes.greyText}>{x.model_auto}</span>
            </div>
            <Typography className={statuses[x.status].className}>
              {statuses[x.photos.length ? x.status : "awaiting_to_load"].title}
            </Typography>
          </div>
          <div className={classes.documentsFiles}>
            {x.photos.map((photo) => (
              <div key={photo.id} className={classes.documentImageWrapper}>
                <div
                  className={classes.documentImageCar}
                  style={{ backgroundImage: `url(${photo.url})` }}
                />
                {x.status === "accepted" ? (
                  <div className={classes.done}>
                    <Done />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div className={classes.settings}>
            <IconButton
              onClick={onClickEdit({
                title: "Автомобили",
                files: x.photos.map((x) => x.url),
                documentType: 'vehicle_photo',
                isCarDocuments: true,
                vehicleId: x.id,
              })}
            >
              <Settings />
            </IconButton>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

const Aggregators = ({ data, classes, location, onGetUser, phoneNumber }) => {
  const [options, setOptions] = React.useState({ open: false, data: {} });
  const statuses = {
    connection_to_request: "Запрос на подключение",
    connected: "Подключен",
    rejected: "Отклонен",
    need_registration: "Требуется регистрация",
    registration_rejected: "Регистрация отклонена",
    registration_requested: "Регистрация запрошена",
    registration_approved: "Регистрация одобрена",
    change_number: "Запрошено изменение номера телефона",
    awaiting_confirmation: "Ожидает подтверждения",
  };

  const onCloseEdit = async (hasChanges = false) => {
    if (hasChanges) {
      await onGetUser();
    }
    setOptions({
      open: false,
      data: {},
    });
  };

  const onOpenEdit = (data) => () => {
    setOptions({
      open: true,
      data,
    });
  };

  return (
    <div>
      {data.map((x) => (
        <div
          key={x.id}
          className={
            x.status === "connection_to_request" || x?.is_updated
              ? classes.servicesWrappRequest
              : classes.servicesWrapp
          }
        >
          <div className={classes.servicesWrappTitleWrap}>
            <p className={classes.servicesWrappTitle}>{x.aggregator_name}</p>
          </div>
          <div className={classes.servicesWrappContent}>
            <div className={classes.servicesWrappContentItem}>
              <p>Телефон</p>
              <p>{x.phone_number}</p>
            </div>
            <div className={classes.servicesWrappContentItem}>
              <p>Статус</p>
              <p>{statuses[x.status] || "Нет данных"}</p>
            </div>
            <div className={classes.servicesWrappContentItem}>
              <p>Дата обновления</p>
              <p>{moment().format("DD MMMM YYYY")}</p>
            </div>
          </div>
          <div className={classes.servicesWrappActions}>
            <IconButton onClick={onOpenEdit(x)}>
              <Settings />
            </IconButton>
          </div>
        </div>
      ))}
      <EnhancedServiceModal
        phoneNumber={phoneNumber}
        onClose={onCloseEdit}
        isOpen={options.open}
        data={options.data}
        userId={location.pathname.replace(/\D/g, "")}
      />
    </div>
  );
};
