import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import fallbackImage from '../../assets/fallback.jpg';

import EditTradesModal from '../../components/EditTradesModal';
import { getTrade, deleteTrade, getTradeTags, getTradeCities } from '../../actions';
import useStyles from './styles';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default ({ location, history }) => {
  const classes = useStyles();
  const [tradeData, setTradeData] = React.useState({ images: [] });
  const [editTradeData, seteditTradeData] = React.useState({ isOpen: false, data: {} });
  const [cities, setCities] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const onGetTrade = React.useCallback(async () => {
    const dataId = location.pathname.replace(/\D/g, '');
    const { success, result } = await getTrade(dataId);
    if (success) {
      setTradeData(result)
    }
  }, [location.pathname])

  const onGetCategories = async () => {
    const { success, results } = await getTradeTags();
    if (success) setCategories(results.map(({ id, name }) => ({ id, type: name, name })))
  }

  const onGetCities = async () => {
    const { success, results } = await getTradeCities();
    if (success) {
      setCities(results);
    }
  }

  React.useEffect(() => {
    onGetCategories()
    onGetCities();
    onGetTrade();
  }, [onGetTrade]);

  const onEdit = () => {
    seteditTradeData({
      isOpen: true,
    });
  }

  const onClose = () => {
    seteditTradeData({
      isOpen: false,
    });
    onGetTrade();
  }

  const onDelete = async () => {
    await deleteTrade(tradeData.id)
    history.push('/dashboard/trade')
  }

  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <ArrowBack className={classes.arrowBack} onClick={() => { history.goBack() }} />
        {tradeData.images.length ?  ( <Carousel className={classes.images}>
          {tradeData.images.map(({url}, i) => (
              <div className={classes.image} key={i} style={{backgroundImage: `url(${url})`}}/>
          ))}

        </Carousel> ) : (
            <img className={classes.fallbackImage} src={fallbackImage} alt="fallback"/>
          )
        }
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>{tradeData.item || 'Товар'}</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div className={classes.flex}>
              <div>
                <span>Название</span>
                <Typography>{tradeData.item || ''}</Typography>
              </div>
              <div>
                <span>Город</span>
                <Typography>{Object(cities?.find(x => x.id === tradeData.city_id)).name}</Typography>
              </div>
            </div>
            <div className={classes.flex}>
              <div>
                <span>Стоимость</span>
                <Typography>{tradeData.price || 0} ₽</Typography>
              </div>
              <div>
                <span>Категория</span>
                <Typography>{Object(categories.find(x => x.id === tradeData.tag_id)).name}</Typography>
              </div>
            </div>
            <div>
              <span>Комментарий</span>
              <Typography>{tradeData.comment}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <ButtonBase onClick={onEdit}>Изменить</ButtonBase>
          <ButtonBase onClick={onDelete}>Удалить</ButtonBase>
        </div>
      </div>
      <EditTradesModal
        isEdit={true}
        isOpen={editTradeData.isOpen}
        data={tradeData || {}}
        onClose={onClose}
        cities={cities}
        categories={categories}
      />
    </div>
  );
};
