import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Tooltip} from "@material-ui/core";

export default makeStyles(theme => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        // alignItems: 'center'
    },
    button: {
        borderRadius: 15,
        minWidth: 30,
        width: 30,
        height: 30
    },
    input: {
        marginBottom: 16
    }
}))

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
