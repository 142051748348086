import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(theme => ({
    wrapper: {
        width: '65vh',
        height: 'auto',
        padding: '20px 25px',
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 'auto',
        transform: 'translateY(5vh)',
    },
    contentItem: {
        margin: '20px 0',
        '& p': {
            color: 'grey'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 40,
        maxHeight: '65vh'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            boxShadow: 'none',
            color: '#fff',
            '&:hover': {
                boxShadow: 'none'
            },
            '&:last-child': {
                marginLeft: 27,
            },
        }
    },
    closeIcon: {
        cursor: 'pointer'
    },
}))
