import React from "react";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";

function EditCommentModal({isOpen, onClose, onSave, data = ''}) {
  const classes = useStyles();
  const [comment, setComment] = React.useState('');
  React.useEffect(() => {
    setComment(data);
  }, [data]);
  
  const changeComment = ({ target: { value } }) => {
    setComment(value);
  }
  
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Комментарий</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItem}>
            <Typography>
              Текст комментария:
            </Typography>
            <textarea
              onChange={changeComment}
              value={comment}
              className={classes.textarea}
              rows="5"
              placeholder="Комметарий"
              type="text"
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="secondary" size="large" variant="contained" onClick={() => {
            setComment(data);
            onClose();
          }}>Отменить</Button>
          <Button color="primary" size="large" variant="contained" onClick={() => {
            onSave(comment);
            onClose();
          }}>Сохранить</Button>
        </div>
      </div>
    </Modal>
  )
}

export default EditCommentModal;
