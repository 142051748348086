import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    marginLeft: 60,
    "& hr": {
      backgroundColor: "rgba(0, 0, 0, 0.62)",
    },
  },
  comment: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& span": {
      margin: "5px 0",
      wordWrap: "break-word",
    },
    "& textarea": {
      padding: 10,
      fontSize: 14,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      resize: "vertical",
      border: "1px solid grey",
      color: "grey",
      borderRadius: 6,
    },
  },
  paper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 4,
    display: "flex",
    position: "relative",
    minHeight: "100vh",
    justifyContent: "space-between",
  },
  wrapperItem: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  sticky: {
    position: "sticky",
    height: 340,
    width: 300,
    top: 0,
    display: "flex",
  },
  arrowBack: {
    cursor: "pointer",
    color: "gray",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 20,
    "& svg": {
      cursor: "pointer",
    },
  },
  documentsDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  documents: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0",
    minHeight: 160,
    margin: "20px 0",
  },
  documentsWaiting: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
    minHeight: 160,
    margin: "20px 0",
    backgroundColor: "#f9e6ca",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      fontSize: 16,
      "&:last-child": {
        color: "red",
        marginLeft: 20,
      },
    },
  },
  documentImage: {
    width: 220,
    height: 120,
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "#999",
    backgroundSize: "contain",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    boxShadow: "1px 5px 5px 1px #bbb",
    marginLeft: 20,
    cursor: "pointer",
  },
  documentImageCar: {
    width: 120,
    height: 120,
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "#999",
    backgroundSize: "contain",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    boxShadow: "1px 5px 5px 1px #bbb",
    marginLeft: 20,
    cursor: "pointer",
  },
  documentsFiles: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    flexWrap: "wrap",
  },
  accepted: {
    color: "#0010ff",
  },
  waiting: {
    color: "black",
  },
  documentsDataTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  documentImageWrapper: {
    position: "relative",
    marginBottom: 15,
  },
  rejected: {
    color: "red",
  },
  done: {
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    width: "92%",
    height: "100%",
    background: "rgba(0,0,0,0.1)",
    right: "20px",
    left: "20px",
    border: "1px solid #0010ff",
    zIndex: 1,
    "& svg": {
      color: "blue",
      margin: "auto",
    },
  },
  greyText: {
    color: "grey",
  },
  image: {
    width: "100%",
    height: 260,
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "#999",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
  },
  userData: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignContent: "flex-start",
    flex: 1,
    "& span": {
      color: "grey",
    },
    "& div": {
      width: 300,
      margin: "20px 0",
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  imageDescription: {
    marginTop: 20,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      fontSize: 20,
      margin: 0,
    },
  },
  servicesWrapp: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  servicesWrappTitleWrap: {
    flex: 1,
  },
  servicesWrappTitle: {
    fontSize: "1rem",
    padding: 0,
    lineHeight: "13px",
  },
  servicesWrappContent: {
    flex: 1,
  },
  servicesWrappContentItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& p": {
      "&:first-child": {
        color: "grey",
      },
    },
  },
  servicesWrappActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginLeft: 20,
  },
  servicesWrappRequest: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "20px",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#f9e6ca",
  },
  commentWrapper: {
    display: "flex",
  },
  editComment: {
    fontSize: 18,
    marginLeft: "auto",
    cursor: "pointer",
  },
  emptyState: {
    marginBottom: 20,
  },
  updatedPaymentHighlight: {
    backgroundColor: "#f9e6ca",
  },
}));
