import React from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/TextField";
import moment from "moment";

import { downloadDocument, rejectorAcceptService } from "../../actions";
import useStyles from "./styles";
import TextareaAutosize from "react-textarea-autosize";

function EnhancedModal({
  isOpen,
  onClose,
  onReject,
  onAccept,
  data,
  userId,
  onRejectorAcceptVehicle,
}) {
  const [reason, setReason] = React.useState("");
  const [hideReason, setHideReason] = React.useState(true);
  const classes = useStyles();
  const onClickReject = () => {
    setHideReason(false);
  };
  const onAcceptReject = () => {
    if (data.isCarDocuments) {
      onRejectorAcceptVehicle({
        vehicle_id: data.vehicleId,
        action: "reject",
        reason,
      });
    } else {
      onReject({ type: data.documentType, reason });
    }
    setHideReason(true);
  };
  const onChangeReason = ({ target: { value } }) => {
    setReason(value);
  };
  const onClickAccept = () => {
    if (data.isCarDocuments) {
      onRejectorAcceptVehicle({
        vehicle_id: data.vehicleId,
        action: "accept",
      });
    } else {
      onAccept({ type: data.documentType });
    }
  };

  const onSave = async () => {
      data.files.map(async (file) => {
        await downloadDocument({ userId, type: data.documentType, file });
      });
  };

  const onClickClose = () => {
    onClose();
    setHideReason(true);
  };
  return (
    <Modal open={isOpen} onClose={onClickClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div>
            <Typography>{data.title}</Typography>
            <span className={classes.greyText}>
              {moment(data.files[0]?.uploaded_at).format(
                "Загружено DD MMMM YYYY"
              )}
            </span>
          </div>
          <CloseIcon onClick={onClickClose} className={classes.closeIcon} />
        </div>
        <div
          className={
            data.isCarDocuments ? classes.quartetContent : classes.content
          }
        >
          {data.files.map((file, i) => (
            <div
              key={i}
              className={data.files.length > 1 ? classes.images : classes.image}
              style={{ backgroundImage: `url(${file})` }}
            />
          ))}
        </div>
        <div className={classes.actions}>
          {hideReason && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={onSave}
            >
              Скачать
            </Button>
          )}
          <div className={classes.flex}>
            {hideReason ? (
              <React.Fragment>
                <Button
                  className={classes.rejectBtn}
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={onClickReject}
                >
                  Отклонить
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={onClickAccept}
                >
                  Подтвердить
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextareaAutosize
                  autoFocus
                  className={classes.changeReason}
                  value={reason}
                  onChange={onChangeReason}
                  placeholder={"Укажите причину"}
                />
                <Button
                  className={classes.changeReasonBtn}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={onAcceptReject}
                >
                  Подтвердить
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function EnhancedServiceModal({ isOpen, onClose, phoneNumber, data }) {
  const [reason, setReason] = React.useState("");
  const [hideReason, setHideReason] = React.useState(true);
  const classes = useStyles();
  const onClickReject = () => {
    setHideReason(false);
  };
  const onAcceptReject = async () => {
    if (reason.length) {
      await rejectorAcceptService({
        phone: phoneNumber,
        aggregator: data.aggregator,
        status: "registration_rejected",
        reason,
      });
      setHideReason(true);
      onClose(true);
    }
  };
  const onChangeReason = ({ target: { value } }) => {
    setReason(value);
  };
  const onClickAccept = async () => {
    await rejectorAcceptService({
      phone: phoneNumber,
      aggregator: data.aggregator,
      status: "registration_approved",
    });

    onClose(true);
  };

  const onClickClose = () => {
    onClose();
    setHideReason(true);
  };
  return (
    <Modal open={isOpen} onClose={onClickClose}>
      <div className={classes.wrapperMin}>
        <div className={classes.header}>
          <div>
            <Typography>{data.aggregator_name}</Typography>
            <p>
              Регистрация в сервисе запрошена пользователем{" "}
              {moment().format("DD MMMM YYYY")}
            </p>
          </div>
          <CloseIcon onClick={onClickClose} className={classes.closeIcon} />
        </div>
        <div className={classes.actions}>
          {hideReason ? (
            <React.Fragment>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={onClickReject}
              >
                Отклонить
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={onClickAccept}
              >
                Подтвердить
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Input
                error={!reason.length}
                label="Укажите причину"
                size="small"
                variant="outlined"
                onChange={onChangeReason}
              />
              <Button
                disabled={!reason.length}
                color="primary"
                size="large"
                variant="contained"
                onClick={onAcceptReject}
              >
                Подтвердить
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default EnhancedModal;
