import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import registerTaxi from "../../assets/registerTaxi.jpg"
import eyeIcon from "../../assets/images/icons/eye.svg"; // import your custom eye icon
import eyeIconOff from "../../assets/images/icons/closeEye.svg";
// import AppBar from '@material-ui/core/AppBar';

import {verify} from '../../actions';

const StyledTypography = styled(Typography)`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color:#121212;
`;

const Container = styled.div`
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  margin: 0 auto 13px auto;
  font-weight: bold;
`;

const ForgotPassword = styled.div`
  margin-top: 40px;
  margin-bottom:20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledPaper = styled(Paper)`
  background-color: transparent;
  box-shadow:none;
`;

const StyledTextField = styled(({ hasContent, isError, ...otherProps }) => <TextField {...otherProps} />)`
    margin-top: 26px;
    margin-bottom: 4px;
    width:100%;
    & .MuiOutlinedInput-root {
        border-radius: 8px;

        & fieldset {
            border: 1px solid ${({ hasContent }) => hasContent ? '#FFB500' : '#CCCCCC'};
        }

        &:hover fieldset {
            border: 1px solid #FFB500 !important;  // Maintain hover border color
        }

        &:focus-within fieldset {
            border: 1px solid #FFB500;  // Border color when focused
        }
    }

    & .MuiFormHelperText-root {
        color: ${({ isError }) => isError ? 'red' : 'inherit'}; // Change helper text color based on error
    }

    & input {
        padding: 15.5px 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    & .MuiInputLabel-outlined {
        transform: translate(22px, 15.5px) scale(1);
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(22px, -6px) scale(0.75);
    }
`;
const ResponsiveImage = styled.img`
//   width: 50%;
//   height: 100vh;
max-height:100vh;
  object-fit: contain;

  @media (max-width: 1000px) {
    display: none; 
  }
`;
const ResponsiveGrid = styled(Grid)`
  min-height: 100vh;
  height: 100%;
  padding-right: 162px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content:space-between;
  .image-container{
    @media (max-width: 1000px) {
        display: none !important; 
      }
  }
  @media (max-width: 1250px) {
  padding-right: 50px;
  }
  @media (max-width: 1000px) {
    justify-content: center;
    padding-right: 0px;
  }
`;
const StyledButton = styled(({ disabled, ...otherProps }) => <Button {...otherProps} />)`
    background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
    color: ${({ disabled }) => disabled ? '#484745' : '#121212'};
    box-shadow: ${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    border-radius: 8px;
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    &:hover {
        background-color: ${({ disabled }) => disabled ? '#FFE4A2' : '#FFB500'};
        box-shadow: ${({ disabled }) => disabled ? 'none' : '1px 1px 4px 0px #00000026'};
    }
`;

export default ({history}) => {
    const { search } = useLocation();  // Get the URL search parameters
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const phone = searchParams.get('contact_phone');
    const [values, changeValues] = React.useState({ phone: '', password: '', checkPassword: '', error: false });
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showCheckPassword, setShowCheckPassword] = React.useState(false);
    React.useEffect(() => {
        // Check if the 'code' query parameter is present
        if (!code) {
            // Redirect to login if no code is present
            history.push('/login');
        }
        else if (phone) {
            // If phone is present, update the state
            changeValues(prevValues => ({ ...prevValues, phone }));
        }
    }, [code,phone, history]);
    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard');
        }
    }, [history])

    React.useEffect(() => {
        const { phone, password, checkPassword } = values;
        setIsDisabled(!phone || !password || !checkPassword || password !== checkPassword);
    }, [values]);

    const onChange = ({target: {value, id}}) => {
        changeValues({...values, error: false, [id]: value});
    }

    const onSignIn = async (data) => {
        const {success, result, type, id} = await verify(data);
        if (success) {
            localStorage.setItem('token', result)
            localStorage.setItem('type', type)
            localStorage.setItem('id', id)
            history.push('/dashboard');
        } else {
            changeValues({ ...values, password: '', checkPassword: '', error: true });
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (values.phone && values.password && values.password === values.checkPassword) {
            onSignIn({ password: values.password, verification_code: code})
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowCheckPassword = () => {
        setShowCheckPassword(!showCheckPassword);
    };
    return (
        <React.Fragment>
            {/* <AppBar position="static">
                <Toolbar>
                    <StyledTypography align="center">Ш-а-ш-ка</StyledTypography>
                </Toolbar>
            </AppBar> */}
             <ResponsiveGrid
                container
                spacing={0}
                alignItems="center"
            >
                <div className='image-container' style={{display:'flex',width:'100%',justifyContent:'center'}}>
                    <ResponsiveImage  src={registerTaxi} alt="registerTaxi-image"/>
                </div>
                <StyledPaper onSubmit={handleSubmit} component="form" >
                    <Container>
                        <Title>
                            <StyledTypography>Регистрация</StyledTypography>
                        </Title>
                        <StyledTextField
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="phone"
                            dataaccessor="data-phone"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={values.phone}
                            onChange={onChange}
                            margin="dense"
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                style: { color: '#949494' },
                            }}
                            hasContent={Boolean(values.phone)}
                        />
                    <StyledTextField
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Пароль"
                            value={values.password}
                            onChange={(e) => changeValues({ ...values, password: e.target.value })}
                            variant="outlined"
                            fullWidth
                            hasContent={Boolean(values.password)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            disableRipple
                                            disableFocusRipple
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding:'13px 16px',
                                                '&:hover': {
                                                  backgroundColor: 'transparent'
                                                }
                                              }}
                                        >
                                            <img src={!showPassword ? eyeIconOff : eyeIcon} alt="Toggle visibility" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                  <StyledTextField
                            type={showCheckPassword ? "text" : "password"}
                            id="checkPassword"
                            name="checkPassword"
                            placeholder="Подтвердите пароль"
                            value={values.checkPassword}
                            onChange={(e) => changeValues({ ...values, checkPassword: e.target.value })}
                            variant="outlined"
                            fullWidth
                            hasContent={Boolean(values.checkPassword)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowCheckPassword}
                                            edge="end"
                                            disableRipple
                                            disableFocusRipple
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding:'13px 16px',
                                                '&:hover': {
                                                  backgroundColor: 'transparent'
                                                }
                                              }}
                                        >
                                            <img src={!showCheckPassword ? eyeIconOff : eyeIcon} alt="Toggle visibility" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>
                            {values.error ? (
                                <Typography color="error">
                                    Не правильный номер или пароль
                                </Typography>
                            ) : null}
                        </div>
                        <ForgotPassword>
                            <Typography style={{color:'#484745',fontFamily: 'Montserrat',fontWeight: 600}}>
                                <Link to="/forgot-password">Нужна помощь?</Link>
                            </Typography>
                        </ForgotPassword>
                        <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isDisabled}
                    >
                        ВОЙТИ
                    </StyledButton>
                    </Container>
                </StyledPaper>
            </ResponsiveGrid>
        </React.Fragment>
    )
} 
