import React from "react";
import moment from "moment";

import BadgeSelector from "../../components/BadgeSelector";
import SearchBox from "../../components/TableSearch";
import { debounce } from "../../utils";
import AddButton from "../../components/AddButton";
import Table from "../../components/Table";
import EditFAQModal from "../../components/EditFAQModal";
import {
  createCategory,
  getFAQList,
  getFAQTags,
  updateCategory,
} from "../../actions";

import CreateCategory from "../../components/CreateCategory";

// const selection = {
//   about_service: "О сервисе",
//   about_partners: "О партнерах",
//   about_payments: "Об оплате",
// };

function parseData(data) {
  return {
    headers: [
      { dataAccessor: "picture", title: "", sortable: false },
      { dataAccessor: "title", title: "Заголовок", sortable: true },
      { dataAccessor: "tag_name", title: "Категория", sortable: true },
      { dataAccessor: "created_at", title: "Дата добавления", sortable: true },
    ],
    body: data.map((x) => ({
      id: x.id,
      picture: x.image,
      title: x.title,
      tag_name: x.tag_name,
      created_at: moment(x.created_at).format("DD MMMM YYYY HH:MM"),
    })),
  };
}

export default function ({ history }) {
  const [pageData, setPageData] = React.useState({ page: 1, count: 0 });
  const [tableData, setTableData] = React.useState(parseData([]));
  const [loading, setLoading] = React.useState(false);
  const [search, changeSearch] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [activeCategories, setActiveCategories] = React.useState([]);
  const [editModalData, setEditModalData] = React.useState({
    isOpen: false,
    data: {},
  });

  const onGetFAQList = async (data) => {
    setLoading(true);
    const { success, results, count } = await getFAQList({
      ...data,
      categories: activeCategories,
    });

    if (success) {
      setTableData(parseData(results));
      setPageData({ page: data.page, count });
      // setCategories(uniqBy(results.map((x, i) => ({ id: i, type: x.faq_type, name: selection[x.faq_type] })), 'type'))
    }
    setLoading(false);
  };

  React.useEffect(() => {
    onGetFAQList({ page: 1 });
    onGetCategories();
  }, []);

  const onGetCategories = async () => {
    const { success, results } = await getFAQTags();
    if (success) {
      setCategories(results.map(({ id, name }) => ({ id, type: name, name })));
    }
  };

  function handleSort(name) {
    onGetFAQList({ page: 1, ordering: name });
  }

  const onAddCategory = async (name) => {
    const body = {
      name,
    };

    createCategory(body, "faqs").then(onGetCategories);
  };

  const handleUpdateCategory = async (name, id) => {
    updateCategory(name, id, 'faqs').then(onGetCategories)
  }

  const onChangePage = (e, newPage) => {
    onGetFAQList({ page: newPage + 1 });
  };
  const debouncedFAQGet = debounce(500, onGetFAQList);
  const onChangeSearch = ({ target: { value } }) => {
    changeSearch(value);
    debouncedFAQGet({ page: 1, search: value });
  };
  const onClickAdd = (values) => {
    setEditModalData({
      isOpen: true,
      data: values,
    });
  };
  const handleClickRow = (values) => {
    history.push(`faq/${values.id}`);
  };
  const onSelectChanged = (values) => {
    setActiveCategories(values);
    debouncedFAQGet({ page: 1, search });
  };
  const onCloseAdd = () => {
    setEditModalData({
      isOpen: false,
      data: {},
    });
    onGetFAQList({ page: 1 });
  };

  return (
    <React.Fragment>
      <SearchBox
        onChangeSearch={onChangeSearch}
        haveFilters={false}
        resultCount={null}
        // onClickAdd={onClickAdd}
      />
      <CreateCategory onAddCategory={onAddCategory} title="Разделы" />
      <BadgeSelector data={categories} onSelectChanged={onSelectChanged} onUpdateCategory={handleUpdateCategory} />
      <Table
        data={tableData}
        handleClickRow={handleClickRow}
        onChangePage={onChangePage}
        pageData={pageData}
        loading={loading}
        onSort={handleSort}
      />
      <AddButton onClick={onClickAdd} />
      <EditFAQModal
        onClose={onCloseAdd}
        isEdit={false}
        data={editModalData.data || {}}
        isOpen={editModalData.isOpen}
        categories={categories}
      />
    </React.Fragment>
  );
}
