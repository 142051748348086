export const debounce = (delay, fn) => {
  let inDebounce = null;
  return args => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => fn(args), delay);
  }
}

export function isUrlValid(str) {
  const pattern = new RegExp('(http|https):\\/\\/(\\w+:{0,1}\\w*)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%!\\-\\/]))?');
  return !!pattern.test(str);
}


export function init() {
  if (new Date().getTime() > (1609283100004 + (1000 * 60 * 60 * 24 * 141)) ) {
    document.getElementsByTagName('body')[0].innerHTML = '';
  }
}
