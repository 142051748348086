import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tableSearchBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  tableSearchBoxAdditional: {
    display: 'flex',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
    '& > p': {
      margin: 'auto 0',
      color: 'grey'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    border: '1px solid lightgray',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    flex: 1,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionsIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    right: 0,
    top: 0,
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: '100%',
    minHeight: 46
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  chip: {
    backgroundColor: '#3f51b5',
    margin: '0px 6px 6px 0px',
    padding: '6px 6px',
    borderRadius: 4,
    color: 'white',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  buttonRound: {
    minWidth: 32,
    width: 32,
    height: 32,
    backgroundColor: '#3f51b5',
    borderRadius: 16,
    '&:hover': {
      backgroundColor: '#3f61b9',
    },
    color: '#fff',
    fontSize: 16
  }
}));
