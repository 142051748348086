import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Photo from '@material-ui/icons/PhotoCameraOutlined';

import { updateTrade, createTrade } from '../../actions';
import useStyles from './styles';
import {Carousel} from "react-responsive-carousel";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";


function EnhancedModal({ isOpen, onClose, data, isEdit, cities, categories }) {
  const [userData, setUserData] = React.useState({
    tag_id: data.tag_id || '',
    images: data.images || [],
    city_id: data.city_id || '',
    price: data.price || '',
    item: data.item || '',
    comment: data.comment || '',
  })

  const [errors, setErrors] = useState({
    tag_id: false,
    images: false,
    city_id: false,
    price: false,
    item: false,
    comment: false,
  })

  const classes = useStyles();

  React.useEffect(() => {
    setUserData({
      tag_id: data.tag_id || '',
      images: data.images?.map(x => x.url) || [],
      city_id: data.city_id || '',
      price: data.price || '',
      item: data.item || '',
      comment: data.comment || '',
    })
  }, [data]);

  const onSave = async () => {
    const formData = new FormData();
    formData.append('item', userData.item);
    formData.append('city', userData.city_id);
    formData.append('price', userData.price);
    formData.append('comment', userData.comment);
    formData.append('tag', userData.tag_id);
    userData.files && Array.from(userData.files).forEach(x => {
      formData.append('images', x);
    });
    if (!Object.keys(userData).map(v => {

      if(!String(userData[v]).length) {
        setErrors(errors => {
          return {...errors, [v]: true}
        })
      }
      return v === 'images' || !!String(userData[v]).length
    }).includes(false)) {
      if (isEdit) {
        const { success } = await updateTrade(formData, data.id)
        if (success) {
          onClose();
        }
      } else {
        const { success } = await createTrade(formData)
        if (success) {
          onClose();
        }
      }
    }
  }

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setUserData({
        ...userData,
        images: Array.from(target.files).map(x => URL.createObjectURL(x)),
        files: target.files
      })
    }
  }
  const onChange = ({ target: { value, name } }) => {
    setErrors({...errors,  [name]: false});
    setUserData({ ...userData, [name]: value });
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography>Окно создания и редактирования товара</Typography>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </div>
        <div className={classes.content}>
          <div className={classes.contentItemWithMap}>
            <Carousel className={classes.images}>
              {userData.images?.length && userData.images.map((image, i) => (
                <div className={classes.image} key={i} style={{ backgroundImage: `url(${image})` }} />
              ))}
            </Carousel>
            {
                !userData.images.length && <div className={classes.image} style={{ backgroundImage: `` }} />
            }
            <input type="file" name="picture" multiple className={classes.input} onChange={onChangeImage} />
            <div className={classes.photoWrapper}>
              <Photo className={classes.photo} />
            </div>
          </div>
          <div className={classes.contentItem}>

            {/*todo:Need key for sending backend*/}
            {/*<div>*/}
            {/*  <Typography>*/}
            {/*    <Box color={errors.category ? 'error.main' : 'text.secondary'}>Категория</Box>*/}
            {/*  </Typography>*/}
            {/*  <TextField*/}
            {/*      error={errors.comment}*/}
            {/*      fullWidth*/}
            {/*      name="category"*/}
            {/*      onChange={onChange}*/}
            {/*      multiline*/}
            {/*      value={userData.comment}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className={classes.flex}>
              <div>
                <Typography>
                  <Box color={errors.item ? 'error.main' : 'text.secondary'}>Название</Box>
                </Typography>
                <TextField
                  error={errors.item}
                  fullWidth
                  name="item"
                  multiline
                  onChange={onChange}
                  value={userData.item}
                />
              </div>
              <FormControl className={classes.formControl} error={errors.city_id}>
                <Typography>
                  <Box color={errors.city_id ? 'error.main' : 'text.secondary'}>Город</Box>
                </Typography>
                <Select fullWidth name="city_id" onChange={onChange} value={userData.city_id}>
                  {
                    cities?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classes.flex}>
              <div>
                <Typography>
                  <Box color={errors.price ? 'error.main' : 'text.secondary'}>Стоимость</Box>
                </Typography>
                <TextField
                  error={errors.price}
                  fullWidth
                  name="price"
                  type="number"
                  className={classes.price}
                  onChange={onChange}
                  value={userData.price}
                />
              </div>
              <FormControl className={classes.formControl} error={errors.tag_id}>
                <Typography>
                  <Box color={errors.tag_id ? 'error.main' : 'text.secondary'}>Категория</Box>
                </Typography>
                <Select fullWidth name="tag_id" onChange={onChange} value={userData.tag_id}>
                  {
                    categories.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div>
              <Typography>
                <Box color={errors.comment ? 'error.main' : 'text.secondary'}>Комментарий</Box>
              </Typography>
              <TextField
                error={errors.comment}
                fullWidth
                name="comment"
                onChange={onChange}
                multiline
                value={userData.comment}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="secondary" size="large" variant="contained" onClick={onClose}>Отменить</Button>
          <Button  color="primary" size="large" variant="contained" onClick={onSave}>
            {isEdit ? 'Сохранить изменения' : 'Создать товар'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnhancedModal;
