import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';

import routes from './routes';

moment.locale('ru');

const PrivateRoute = ({ routes, component: Component, ...rest }) => {
  if (localStorage.getItem('token'))
    return <Route {...rest} render={props => <Component {...props} routes={routes} />} />
  localStorage.removeItem('token');
  localStorage.removeItem('type');
  localStorage.removeItem('id');
  return <Redirect to="/login" />
}

export default (props) => {
  return (
    <Switch>
      <Redirect from="/" to="/dashboard" exact />
      {routes.map(({ auth, component: Component, ...rest }, i) =>
        auth ?
          <PrivateRoute key={i} {...rest} component={Component} />
          :
          <Route key={i} {...rest} component={Component} />
      )}
    </Switch>
  );
}
