import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    flex: 1,
    marginLeft: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& hr': {
      backgroundColor: 'rgba(0, 0, 0, 0.62)',
    }
  },
  paper: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 4,
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
  },
  wrapperItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  sticky: {
    position: 'sticky',
    height: 340,
    display: 'flex',
    top: 0,
    '& > div': {
      marginLeft: 10,
    }
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 20,
    '& svg': {
      cursor: 'pointer'
    }
  },
  documentsDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  arrowBack: {
    cursor: 'pointer',
    color: 'gray',
  },
  documents: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
    minHeight: 160,
    margin: '20px 0',
  },
  documentsWaiting: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    minHeight: 160,
    margin: '20px 0',
    backgroundColor: '#f9e6ca'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0',
    '& button': {
      fontSize: 16,
      '&:last-child': {
        color: 'red',
        marginLeft: 20,
      }
    }
  },
  documentImage: {
    width: 220,
    height: 120,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#999',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    boxShadow: '1px 5px 5px 1px #bbb',
    marginLeft: 20,
    cursor: 'pointer',
  },
  documentsFiles: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  accepted: {
    color: '#0010ff',
  },
  waiting: {
    color: 'black',
  },
  documentsDataTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  documentImageWrapper: {
    position: 'relative',
  },
  done: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '92%',
    height: '100%',
    background: 'rgba(0,0,0,0.1)',
    right: '20px',
    left: '20px',
    border: '1px solid #0010ff',
    zIndex: 1,
    '& svg': {
      color: 'blue',
      margin: 'auto'
    }
  },
  greyText: {
    color: 'grey'
  },
  image: {
    width: 300,
    height: 260,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#999',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  userData: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    flex: 1,
    '& span': {
      color: 'grey',
    },
    '& > div': {
      width: '100%',
      padding: '20px 0'
    }
  },
  dates: {
    display: 'flex',
    '& div': {
      marginRight: 50,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageDescription: {
    marginTop: 20,
  },
  header: {
    '& > p': {
      fontSize: 20,
      margin: 0,
    },
  }
}))