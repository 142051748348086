import React from "react";
import styled from "styled-components";
import { render } from "react-dom";
import mapboxgl from "mapbox-gl";
import IconButton from "@material-ui/core/IconButton";
import Settings from "@material-ui/icons/Settings";

const accessToken =
  "pk.eyJ1IjoibXItbHVrZSIsImEiOiJjazlrM3d4eTAwNGdvM2xxaGZ3eHl6NDZqIn0.wPgdbup_0vmX-SoOfXiyfg";
mapboxgl.accessToken = accessToken;

const MapContainer = styled.div`
  height: 500px;
  width: 100%;
  border-radius: 4px;
  background-color: #555;
  margin-bottom: 20px;
  position: relative;
`;

const PopupContainer = styled.div`
  display: flex;
  minwidth: 300px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const PopupImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: auto 5px;
  background: grey;
`;

const PopupTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 190px;
  p {
    margin: 0;
  }
`;

const PopupAction = styled.div``;

const createPopUp = ({ picture, address, name, ...rest }, setModalData) => {
  const popup = window.document.createElement("div");
  const html = (
    <PopupContainer>
      <PopupImage src={picture} />
      <PopupTitle>
        <p>{name}</p>
        <p>{address}</p>
      </PopupTitle>
      <PopupAction>
        <IconButton
          onClick={() =>
            setModalData({
              isOpen: true,
              picture,
              address,
              placeTitle: name,
              ...rest,
            })
          }
        >
          <Settings />
        </IconButton>
      </PopupAction>
    </PopupContainer>
  );
  render(html, popup);
  return popup;
};

class Map extends React.Component {
  mapRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      lng: 37.6,
      lat: 55.75,
      zoom: 9,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.pageData) !== JSON.stringify(this.props.pageData)
    ) {
      const { lng, lat, zoom } = this.state;
      const map = new mapboxgl.Map({
        container: this.mapRef.current,
        style: "mapbox://styles/mapbox/streets-v9",
        center: [lng, lat],
        zoom,
      });
      const markers = [];

      this.props.data.forEach((x) => {
        const html = createPopUp(x, this.props.setModalData);
        markers.push(
          new mapboxgl.Marker()
            .setLngLat(x.point.coordinates.reverse())
            .setPopup(
              new mapboxgl.Popup().setDOMContent(html).setMaxWidth("300px")
            )
            .addTo(map)
        );
      });
      this.props.renderMarkersEvents(markers);

      map.on("move", () => {
        const { lng, lat } = map.getCenter();

        this.setState({
          lng: lng.toFixed(4),
          lat: lat.toFixed(4),
          zoom: map.getZoom().toFixed(2),
        });
      });
    }
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [lng, lat],
      zoom,
    });

    map.on("move", () => {
      const { lng, lat } = map.getCenter();

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
    });
    const markers = [];
    if (this.props.onClick) {
      map.on("click", async (e) => {
        const res = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=${accessToken}`
        );
        const resp = await res.json();
        markers.forEach((m) => m.remove());
        markers.push(new mapboxgl.Marker().setLngLat(e.lngLat).addTo(map));
        this.props.onClick(e, resp.features);
      });
    }
  }

  render() {
    const { lng, lat, zoom } = this.state;

    return (
      <MapContainer className={this.props.className}>
        <div className="inline-block absolute top left mt12 ml12 bg-darken75 color-white z1 py6 px12 round-full txt-s txt-bold">
          <div>{`Longitude: ${lng} Latitude: ${lat} Zoom: ${zoom}`}</div>
        </div>
        <div ref={this.mapRef} className="mapContainer" />
      </MapContainer>
    );
  }
}

export default Map;
