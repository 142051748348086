import React, { useEffect, useState } from "react";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import useStyles from "./styles";
import Photo from "@material-ui/icons/PhotoCameraOutlined";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { createNews, getNews, updateNews } from "../../actions";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import { onGetCategories } from "../News";
import FormControl from "@material-ui/core/FormControl";

function AddNews({ history, location }) {
  const classes = useStyles();
  const dataId = location.pathname.replace(/\D/g, "");
  const [categories, setCategories] = useState([]);
  const isEdit = !!dataId;

  const [userData, setUserData] = React.useState({
    tag: "",
    image: "",
    active_date_from: new Date().toISOString(),
    active_date_to: new Date().toISOString(),
    title: "",
    text: "",
  });

  const [errors, setErrors] = useState({
    tag: false,
    title: false,
    text: false,
  });

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isEdit) {
      getNews(dataId).then((data) => {
        const { success, result } = data;

        if (success) {
          setUserData({
            tag: result.tag || "",
            image: result.image || "",
            active_date_from: result.active_date_from || "",
            active_date_to: result.active_date_to || "",
            title: result.title || "",
            text: result.description || "",
          });
        }
      });
    }

    onGetCategories().then(setCategories);
  }, [dataId, isEdit]);

  const onSave = async () => {
    if (
      !Object.keys(errors)
        .map((v) => {
          const isTag = v === "tag";

          const isDate = v === 'active_date_from' || v === 'active_date_to';

          const isInvalid = isTag
            ? typeof userData[v] !== "number"
            : typeof userData[v] === "undefined" || !userData[v].length;

          if (!isDate && isInvalid) {
            setErrors((errors) => {
              return { ...errors, [v]: true };
            });
          }
          return isDate || v === "image" || (isTag ? !isInvalid : !!userData[v].length);
        })
        .includes(false)
    ) {
      const formData = new FormData();
      formData.append("tag", userData.tag);
      formData.append(
        "created_at",
        new Date().toISOString()
      );
      formData.append("title", userData.title);
      userData.file && formData.append("image", userData.file);
      formData.append("description", userData.text);
      formData.append("active_date_from", userData.active_date_from);
      formData.append("active_date_to", userData.active_date_to);

      if (isEdit) {
        const { success } = await updateNews(formData, dataId);
        if (success) {
          onBack();
        }
      } else {
        const { success } = await createNews(formData);
        if (success) {
          onBack();
        }
      }
    }
  };

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      setUserData({
        ...userData,
        image: URL.createObjectURL(target.files[0]),
        file: target.files[0],
      });
    }
  };

  const onChange = (value, name) => {
    setErrors({ ...errors, [name]: false });

    setUserData({ ...userData, [name]: value });
  };

  return (
    <div className={classes.paper}>
      <div className={classes.sticky}>
        <ArrowBack className={classes.arrowBack} onClick={onBack} />
        <div className={classes.contentItemWithMap}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url(${userData.image})` }}
          />
          <input
            type="file"
            name="picture"
            className={classes.input}
            onChange={onChangeImage}
          />
          <div className={classes.photoWrapper}>
            <Photo className={classes.photo} />
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperItem}>
          <div className={classes.header}>
            <Typography>Новость</Typography>
          </div>
          <Divider />
          <div className={classes.userData}>
            <div className={classes.contentItem}>
              <div>
                <Typography>
                  <Box color={errors.tag ? "error.main" : "text.secondary"}>
                    Категория
                  </Box>
                </Typography>
                <FormControl className={classes.formControl} error={errors.tag}>
                  <Select
                    onError={errors.tag}
                    fullWidth
                    value={userData.tag}
                    name="tag"
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                  >
                    {categories?.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Typography>
                  <Box color={errors.title ? "error.main" : "text.secondary"}>
                    Заголовок
                  </Box>
                </Typography>
                <TextField
                  error={errors.title}
                  fullWidth
                  name="title"
                  multiline
                  onChange={(e) => onChange(e.target.value, e.target.name)}
                  value={userData.title}
                />
              </div>
              <div className={classes.flex}>
                <Typography>Активна</Typography>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        <Box mr={2} mt={1}>
                          С
                        </Box>
                      </Typography>
                      <DatePicker
                        style={{ width: 150 }}
                        margin="normal"
                        id="date-picker-dialog"
                        format="MM/dd/yyyy"
                        value={userData.active_date_from}
                        onChange={(e) => onChange(e.toISOString(), "active_date_from")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <TimePicker
                        margin="normal"
                        style={{ width: 130 }}
                        id="time-picker"
                        value={userData.active_date_from}
                        onChange={(e) => onChange(e.toISOString(), "active_date_from")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        name="activeFrom"
                      />

                      <Typography>
                        <Box mt={1} ml={2} mr={2}>
                          По
                        </Box>
                      </Typography>
                      <DatePicker
                        style={{ width: 150 }}
                        margin="normal"
                        id="date-picker-dialog"
                        format="MM/dd/yyyy"
                        value={userData.active_date_to}
                        onChange={(e) => onChange(e.toISOString(), "active_date_to")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <TimePicker
                        style={{ width: 130 }}
                        margin="normal"
                        id="time-picker"
                        value={userData.active_date_to}
                        onChange={(e) => onChange(e.toISOString(), "active_date_to")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        name="activeFrom"
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <Typography>
                  <Box color={errors.text ? "error.main" : "text.secondary"}>
                    Текст
                  </Box>
                </Typography>
                <TextField
                  error={errors.text}
                  fullWidth
                  name="text"
                  multiline
                  onChange={(e) => onChange(e.target.value, e.target.name)}
                  value={userData.text}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={onBack}
          >
            Отменить
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onSave}
          >
            {isEdit ? "Сохранить изменения" : "Создать новость"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddNews;
