import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    width: 800,
    maxWidth: '85vh',
    height: '90vh',
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapperMin: {
    position: 'absolute',
    minWidth: 400,
    minHeight: 200,
    padding: '20px 25px',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  greyText: {
    color: 'grey'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
    height: 600,
    flexWrap: 'wrap',
    overflow: 'scroll',
  },
  quartetContent: {
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
    height: 600,
    flexWrap: 'wrap',
    '& > div': {
      width: '48%',
      height: '50%',
      marginBottom: 10,
    }
  },
  image: {
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    height: '100%',
  },
  images: {
    width: '50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    height: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      boxShadow: 'none',
      color: '#fff',
      width: 150,
      '&:hover': {
        boxShadow: 'none'
      },
      '&:last-child': {
        marginLeft: 27,
      },
    }
  },
  closeIcon: {
    cursor: 'pointer'
  },
  rangeInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      flex: 1,
      width: '100%',
    },
    '&>div:first-child': {
      marginRight: 20
    },
    '&>div:last-child': {
      marginLeft: 20
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  formControl: {
    userSelect: 'none',
    marginBottom: 20,
  },
  rejectBtn: {
    marginLeft: 'auto',
  },
  changeReason: {
    width: '75%',
    minHeight: 50,
  },
  changeReasonBtn: {
    width: '20%',
    marginLeft: '5%',
    maxHeight: 50,
    marginTop: 'auto',
  }
}));
